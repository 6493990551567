import {Link} from "react-router-dom";
import {apiGet} from "../Tools/apiConfig";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useQuery} from "react-query";

const BlogCategory = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["BlogCategory"],
        async () => {
            const baseURL = '/wp-json/wp/v2/categories?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';
            return await apiGet(baseURL)
        }
    );

    return (
        <ul>
            {!isLoading ? data.data.map((item, index) => {
                return (
                    <li key={index}>
                        <Link to={`/blog/category/${item.slug}`}><div dangerouslySetInnerHTML={{__html: item.name}}/></Link>
                    </li>
                )
            }) : Array(3).fill(0).map((item, index) =>
                <Skeleton key={index}/>)}
        </ul>

    );
};

export default BlogCategory;