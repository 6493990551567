import {Link} from "react-router-dom";

const About = ({data}) => {
    return (
        <section className="about spad ptb-100 mt-5 mt-lg-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="about__pic">
                            <div className="about__pic__inner home_page">
                                <img
                                    src={data.filter(el => el.slug === "homeaboutus")[0].better_featured_image.source_url}
                                    alt={data.filter(el => el.slug === "homeaboutus")[0].head_title}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="about__text paddin-home">
                            <div className="section-title">
                                <h2>
                                    {data.filter(el => el.slug === "homeaboutus")[0].head_title}
                                </h2>
                            </div>
                            <div className="about__para__text mb-3">
                                <div
                                    dangerouslySetInnerHTML={{__html: data.filter(el => el.slug === "homeaboutus")[0].content.rendered}}/>
                            </div>
                            <div className="block-more mt-4">
                                <Link to="/about" className="primary-btn normal-btn">Learn More</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default About;