
import Services from "../../components/Home/Services";
import ServicesCard from "../../components/Services/ServicesCard";
import ServicesCardSkeleton from "../../components/Services/ServicesCardSkeleton";
import {apiGet} from "../../components/Tools/apiConfig";
import {Helmet} from "react-helmet";
import {useQuery} from "react-query";

const ServicesList = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Services"],
        async () => {
            let fields = 'title,content,better_featured_image,featured_media,slug,show_on_home,home_content,icon_class'
            const baseURL = "/wp-json/wp/v2/service?_fields=" + fields;
            return await apiGet(baseURL)
        }
    );

    return (
        <>
            <Helmet>
                <title>IoT Academy - Services</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Services</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="services-area ptb-100">
                <div className="container">
                    <div className="row">
                        {
                            (isLoading || !isSuccess) ?
                                <div className="blog-list blog-side-image">
                                    {
                                        Array.apply(0, Array(6)).map((item, i) =>
                                            <ServicesCardSkeleton key={i}/>
                                        )
                                    }
                                </div> :
                                <div className="blog-list blog-side-image">
                                    {data.data.map((item, i) =>
                                        <ServicesCard key={i} elements={item}/>
                                    )}

                                </div>


                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesList;