import React from 'react';
import {Link} from "react-router-dom";
import {apiGet, apiPost} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet";

const ResetPassword = () => {

    const resetPassword = (e) => {
        e.preventDefault()
        let userRestPassData = {
            email: document.getElementsByName('email')[0].value,
            AUTH_KEY: 'AUTH_KEY'
            // privacy: document.getElementById('check1').checked
        }

        if (userRestPassData.email != '') {
            let baseURL = `/?rest_route=R/simple-jwt-login/v1/user/reset_password`;
            try {
                const fetchData = apiPost(baseURL, userRestPassData).then((response) => {
                    console.log(response)
                    console.log(response.data)
                    if (response.data.success) {
                        toast.success("Check Your Emails!");

                    } else {
                        toast.error("Check Your Inputs!");
                    }
                })
            } catch (err) {
                console.error(err);
            }
            document.getElementById('resetPassword').reset()
        } else {
            toast.error("Check Your Inputs!");
        }
    }

    return (
        <>
            <Helmet>
                <title>IoT Academy - ResetPassword</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            IoT Academy Forget Password
                        </h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Forget Password</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="no-bottom no-top" id="content">`

                <section className="relative vertical-center ptb-100">
                    <div className="overlay-gradient t50">
                        <div className="center-y relative">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-4 mx-auto wow fadeIn" data-wow-delay=".5s">
                                        <div className="box-rounded padding40" data-bgcolor="#ffffff">
                                            <h3 className="mb10">Forget Password</h3>
                                            <p/>
                                            <form name="resetPassword" id='resetPassword' className="form-border"
                                                  onSubmit={resetPassword}>

                                                <div className="field-set">
                                                    <input type='email' name='email' id='email'
                                                           className="form-control"
                                                           placeholder="Email Address"/>
                                                </div>
                                                <p/>
                                                <div className="field-set">
                                                    <input type='submit' id='send_message' value='Forget Password'
                                                           className="btn btn-secondary "
                                                    />
                                                </div>

                                                <div className="clearfix"/>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        </>
    );
};

export default ResetPassword;