import React from 'react';
import {apiPost} from "./apiConfig";

export const UserMeta = async () => {
    let localData = localStorage.getItem('gd-auth')
    if (localData) {
        const baseURL = "/api/user/get_user_meta/?cookie=" + JSON.parse(localData).cookie
        try {
            const fetchData = await apiPost(baseURL).then((response) => {
                window.localStorage.setItem('user_meta', JSON.stringify(response.data))
            });
        } catch (err) {
            console.error(err);
        }
    }
};