import {Link} from "react-router-dom";
import {apiGet} from "../Tools/apiConfig";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {useQuery} from "react-query";

const EventCategory = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["EventCategory"],
        async () => {
            const baseURL = "/wp-json/wc/v3/products/categories?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish";
            return await apiGet(baseURL)
        }
    );

    return (
        <>
            {!isLoading ? data.data.map((item, index) => {
                return (
                    <div className="abgne-menu-20140101-2" key={index}>
                        <Link to={`/event/category/${item.slug}`}>{item.name}</Link>
                    </div>
                )
            }) : Array(3).fill(0).map((item, index) =>
                <Skeleton key={index} width={60} className="ms-1"/>)}
        </>

    );
};

export default EventCategory;