import React, {useState} from 'react';
import {Helmet} from "react-helmet"
import {apiPost} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";

const Contact = () => {
    const [alert, setAlert] = useState(0);
    const [warning, setWarning] = useState(0);
    const [res, setRes] = useState("");
    const contactUsSubmit = (e) => {
        e.preventDefault();
        setWarning(0);
        setAlert(0);
        let name = document.getElementsByName('name')[0].value;
        let email = document.getElementsByName('email')[0].value;
        let subject = document.getElementsByName('subject')[0].value;
        let text = document.getElementsByName('message')[0].value;
        if (name !== "" && email !== "" && subject !== "" && text !== "") {
            let formdata = new FormData();
            formdata.append("your-name", name);
            formdata.append("your-email", email);
            formdata.append("your-subject", subject);
            formdata.append("your-message", text);

            const baseURL = "/wp-json/contact-form-7/v1/contact-forms/5/feedback"
            apiPost(baseURL, formdata)
                .then(response => {
                    if (response.data.status === "mail_sent") {
                        setRes(response.data.message);
                        setAlert(1);
                        toast.success("Your Message Successfully Sent!");
                        document.getElementById('contactForm').reset();
                    } else {
                        setWarning(1);
                        toast.error("Check Your Inputs!");
                    }

                }).catch((e) => {
                setWarning(1);
                toast.error("Something Went Wrong!")
            })

        } else {
            toast.error("Check Your Inputs!");
        }

    }
    return (
        <>
            <Helmet>
                <title>IoT Academy - Contact Us</title>
            </Helmet>

            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Contact</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="contact-area ptb-100">
                <div className="contact" id="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-5">
                                <div className="title">
                                    <h3>Contact Us</h3>
                                    <p>ITU IoT Center of Excellence in the Asia and the Pacific Region </p>
                                </div>
                                <div className="content">
                                    <div className="info" style={{display: "flex"}}>
                                        <i className="fas fa-mobile-alt"/>
                                        <h4 className="d-inline-block">
                                            PHONE :
                                            <br/>
                                            <span>
                                    <a className="d-inline-block"
                                       href="tel:+982186081025">(+98) 2186081025</a>
                                </span>
                                        </h4>
                                    </div>
                                    <div className="info">
                                        <i className="far fa-envelope"/>
                                        <h4 className="d-inline-block">
                                            EMAIL :
                                            <br/>
                                            <span>
                                    <a className="d-inline-block"
                                       href="mailto:office@iotaci.com">office@iotaci.com</a>
                                </span>
                                        </h4>
                                    </div>
                                    <div className="info" style={{display: "flex"}}>
                                        <i className="fas fa-map-marker-alt"/>
                                        <h4 className="d-inline-block">
                                            ADDRESS1 :<br/>
                                            <span>Building 1, University of Tehran Science and Technology Park, 16th Street, North Kargar St., Tehran, Iran.</span>
                                        </h4>
                                        {/*<h4 className="d-inline-block">*/}
                                        {/*    ADDRESS2 :<br/>*/}
                                        {/*    <span>Floor 1, Institute of Petroleum Engineering, College of Engineering, University of Tehran, North Kargar St., Tehran, Iran.</span>*/}
                                        {/*</h4>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-7">
                                <form id="contactForm" onSubmit={contactUsSubmit}>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <input type="text" className="form-control" placeholder="Name" name="name"
                                                   id="messageNameContant" required
                                                   data-error="Please enter your name"/>
                                            <div className="help-block with-errors"/>
                                        </div>
                                        <div className="col-sm-6">
                                            <input type="email" className="form-control" placeholder="Email"
                                                   name="email"
                                                   id="messageEmailContant" required
                                                   data-error="Please enter your email"/>
                                            <div className="help-block with-errors"/>
                                        </div>
                                        <div className="col-sm-12">
                                            <input type="text" className="form-control" name="subject"
                                                   placeholder="Subject"
                                                   id="messageSubContant" required
                                                   data-error="Please enter your subject"/>
                                            <div className="help-block with-errors"/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                            <textarea className="form-control" rows="5" name="message" id="messageTxtContant"
                                      placeholder="Type Your Message Here" required
                                      data-error="Write your message"/>
                                        <div className="help-block with-errors"/>
                                    </div>
                                    <button id="btnSubmitForm" type="submit" className="btn btn-block">Send Message
                                    </button>
                                    <div id="msgSubmit" className="h3 text-center d-none"/>
                                    <div className="clearfix"/>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
            <div id="map">
                <iframe className="map-contactUS"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4478.962092303009!2d51.388659054979016!3d35.73303864454276!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e06d622f22575%3A0x1653a89e2c7a0af2!2sUniversity%20of%20Tehran%20Science%20%26%20Technology%20Park!5e0!3m2!1sen!2s!4v1637261501542!5m2!1sen!2s"
                        width="100%" height="300" style={{border: "0"}} allowFullScreen="" loading="lazy"/>
            </div>
        </>
    );
};

export default Contact;