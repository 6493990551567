import Skeleton from "react-loading-skeleton";

const AboutSkeleton = () => {
    return (
        <section className="about spad ptb-100 mt-5 mt-lg-0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="about__pic">
                            <div className="about__pic__inner home_page">
                                <Skeleton height={300} width={200}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="about__text paddin-home">
                            <div className="section-title">
                                <h2>
                                    <Skeleton width={"90%"}/>
                                </h2>
                            </div>
                            <div className="about__para__text mb-3">
                                <div>
                                    <Skeleton count={10}/>
                                </div>
                            </div>
                            <div className="block-more mt-4">
                                <Skeleton width={"40%"}/>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
};

export default AboutSkeleton;