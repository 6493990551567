import {useContext, useEffect, useState} from 'react';
import FaqItem from "../../components/Common/FaqItem";
import {FaqDataContext} from "../../context/FaqDataContext";
import {apiGet} from "../../components/Tools/apiConfig";
import {Helmet} from "react-helmet";
import {useQuery} from "react-query";

const Faq = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["faq"],
        async () => {
            let fields = '_fields[]=title&_fields[]=content';
            const baseURL = "/wp-json/wp/v2/faq?per_page=100&" + fields;
            return await apiGet(baseURL)
        }
    );

    return (
        <>
            <Helmet>
                <title>IoT Academy - FAQ</title>
            </Helmet>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Frequently Asked Questions</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>FAQ</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="faq-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="faq-accordion">
                                <ul className="accordion">
                                    {!isLoading ? data.data.map((item, index) => {
                                        return (
                                            <FaqItem key={index} element={item}/>
                                        )
                                    }) : <div className="text-center"><i className="fa fa-spinner fa-spin"/></div>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faq;