import axios from "axios";

export function apiGet(url, headers) {
    return axios.get('https://api.iotaci.com' + url, {...headers})
}

export function apiPost(url, requestData, headers) {
    return axios.post('https://api.iotaci.com' + url, requestData, headers);
}

export function apiPut(url, requestData) {
    return axios.put('https://api.iotaci.com' + url, requestData);
}