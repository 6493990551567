import {useRef} from 'react';
import BlogCard from "../../components/Blog/BlogCard";
import BlogCardSkeleton from "../../components/Blog/BlogCardSkeleton";
import {apiGet} from "../../components/Tools/apiConfig";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard} from "swiper";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";

const Blog = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)

    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["blogs"],
        async () => {
            const baseURL = "?rest_route=/wp/v2/posts&_embed&per_page=6";
            return await apiGet(baseURL)
        }
    );

    return (
        <section className="latest spad ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-8 col-12">
                        <div className="section-title mx-0">
                            <h2 className="text-center text-lg-start">
                                IoT Academy News
                            </h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                        <div className="latest__btn">
                            <Link to="/blog" className="primary-btn normal-btn">View All</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="blogCarousle position-relative">
                        <div className="logo__carousel__item">
                            <Swiper
                                modules={[Navigation, Keyboard]}
                                spaceBetween={30}
                                slidesPerView={1}
                                loop={true}
                                breakpoints={{
                                    992: {
                                        slidesPerView: 3
                                    },
                                    767: {
                                        slidesPerView: 3,
                                    }
                                }}
                                keyboard={{enabled: true, onlyInViewport: true}}
                                navigation={{
                                    prevEl: navigationPrevRef.current,
                                    nextEl: navigationNextRef.current,
                                }}
                            >
                                {

                                    (isLoading || !isSuccess) ?
                                        Array.apply(0, Array(6)).map((item, i) =>
                                            <SwiperSlide className="mb30" key={i}>
                                                <BlogCardSkeleton/>
                                            </SwiperSlide>
                                        ) :
                                        data.data.slice(0, 6).map((item, i) =>
                                            <SwiperSlide className="mb30" key={i}>
                                                <BlogCard elements={item}/>
                                            </SwiperSlide>
                                        )
                                }
                            </Swiper>
                            <div ref={navigationPrevRef} className="navigationBtn navPrev">
                                <i className="fas fa-chevron-left"/>
                            </div>
                            <div ref={navigationNextRef} className="navigationBtn navNext">
                                <i className="fas fa-chevron-right"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>


    );
};

export default Blog;