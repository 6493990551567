import React, {useState} from 'react';
import {Helmet} from "react-helmet"
import {apiPost} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";
import '../../App.css';
const ContactForm = () => {
    const [alert, setAlert] = useState(0);
    const [warning, setWarning] = useState(0);
    const [res, setRes] = useState("");
    const contactUsSubmit = (e) => {
        e.preventDefault();
        setWarning(0);
        setAlert(0);
        let name = document.getElementsByName('name')[0].value;
        let family = document.getElementsByName('family')[0].value;
        let email = document.getElementsByName('email')[0].value;
        let country = document.getElementsByName('country')[0].value;

        if (name !== "" && email !== "" && family !== "" && country !== "") {
            let formdata = new FormData();
            formdata.append("your-name", name);
            formdata.append("your-family", family);
            formdata.append("your-email", email);
            formdata.append("your-country", country);


            const baseURL = "/wp-json/contact-form-7/v1/contact-forms/447/feedback"
            apiPost(baseURL, formdata)
                .then(response => {
                    if (response.data.status === "mail_sent") {
                        setRes(response.data.message);
                        setAlert(1);
                        toast.success("Your Message Successfully Sent!");
                        document.getElementById('contactForm').reset();
                    } else {
                        setWarning(1);
                        toast.error("Check Your Inputs!");
                    }

                }).catch((e) => {
                setWarning(1);
                toast.error("Something Went Wrong!")
            })

        } else {
            toast.error("Check Your Inputs!");
        }

    }
    return (
        <section className="contact-area ptb-100 bg-darkgray">
            <div className="contact" id="contact">
                <div className="container">
                    <div className="row">
                        <section className="contact-area ptb-100">
                            <div className="contact" id="contact">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-5 ">
                                            <div className="title">
                                                <h3>Contact Us</h3>
                                                <p>ITU IoT Center of Excellence in the Asia and the Pacific Region </p>
                                            </div>
                                            <div className="content">
                                                <div className="info" style={{display: "flex"}}>
                                                    <i className="fas fa-mobile-alt"/>
                                                    <h4 className="d-inline-block">
                                                        PHONE :
                                                        <br/>
                                                        <span>
                                    <a className="d-inline-block"
                                       href="tel:+982186081025">(+98) 2186081025</a>
                                </span>
                                                    </h4>
                                                </div>
                                                <div className="info">
                                                    <i className="far fa-envelope"/>
                                                    <h4 className="d-inline-block">
                                                        EMAIL :
                                                        <br/>
                                                        <span>
                                    <a className="d-inline-block"
                                       href="mailto:office@iotaci.com">office@iotaci.com</a>
                                </span>
                                                    </h4>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-7 mt-space">
                                            <div className="title">
                                                <h3>Contact Form</h3>
                                            </div>
                                                <form id="contactForm" onSubmit={contactUsSubmit}>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <input type="text" className="form-control"
                                                                   placeholder="Name" name="name"
                                                                   id="messageNameContant" required
                                                                   data-error="Please enter your name"/>
                                                            <div className="help-block with-errors"/>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <input type="text" className="form-control"
                                                                   placeholder="family"
                                                                   name="family"
                                                                   id="messagefamilyContant" required
                                                                   data-error="Please enter your family"/>
                                                            <div className="help-block with-errors"/>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <input type="email" className="form-control"
                                                                   placeholder="email"
                                                                   name="email"
                                                                   id="messageEmailContant" required
                                                                   data-error="Please enter your email"/>
                                                            <div className="help-block with-errors"/>
                                                        </div>
                                                        <div className="col-sm-12">
                                                            <input type="text" className="form-control"
                                                                   placeholder="country" name="country"
                                                                   id="messagecountryContant" required
                                                                   data-error="Please enter your country"/>
                                                            <div className="help-block with-errors"/>
                                                        </div>


                                                    </div>

                                                    <button id="btnSubmitForm" type="submit"
                                                            className="btn btn-block">Send Message
                                                    </button>
                                                    <div id="msgSubmit" className="h3 text-center d-none"/>
                                                    <div className="clearfix"/>
                                                </form>


                                        </div>
                                    </div>
                                </div>
                            </div>


                        </section>
                    </div>
                </div>
            </div>


        </section>

    );
};

export default ContactForm;