import {Parallax} from 'react-scroll-parallax';

const Banner = () => {

    return (
        <section className="our-coverage-area ptb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-10 col-md-12 image_banner_cover">
                        <div className="coverage-area-map">
                            <img src="/assets/img/maping.png" alt="banner"/>
                        </div>
                    </div>
                    <Parallax
                        speed={0.5}
                        scale={[1, 1.5]}
                        translateX={[
                            '-50%',
                            '-50%'
                        ]}
                        translateY={[
                            '-40%',
                            '40%'
                        ]}
                        className={"image_banner_content"}>

                        <div className="col-lg-12 col-md-12">
                            <div className="coverage-area-content">
                                <h2>
                                    IoT Academy International Positions
                                </h2>

                                <div>
                                    <a className="text-dark mb-3 bannerLinks" title={"View More"} target={"_blank"}
                                       rel="noreferrer"
                                    href="https://academy.itu.int/itu-d/projects-activities/centres-excellence/coe-cycles/coe-cycle-2019-2022">
                                    ITU Center of Excellence (CoE) in the Priority area of Internet of Things
                                    for Asia and the Pacific
                                    <a href="https://academy.itu.int/itu-d/projects-activities/centres-excellence/coe-cycles/coe-cycle-2019-2022"
                                       className="details-btn mx-2"><i
                                        className="flaticon-arrow-pointing-to-right"/></a>
                                </a>

                                <a className="text-dark bannerLinks" title={"View More"} target={"_blank"}
                                   rel="noreferrer"
                                href="https://www.itu.int/en/ITU-D/MembersPartners/Pages/Members/members.aspx?Type=U">
                                International Telecommunication Union (ITU) Academia Member
                                <a href="https://www.itu.int/en/ITU-D/MembersPartners/Pages/Members/members.aspx?Type=U"
                                   className="details-btn mx-2"><i className="flaticon-arrow-pointing-to-right"/></a>
                            </a>
                        </div>
                </div>
            </div>
        </Parallax>

</div>
</div>
</section>
)
    ;
};

export default Banner;