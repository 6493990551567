const ServicesHomeCard = ({elements}) => {
    return (
        <div className="col-lg-4 col-md-6 col-12 mb-3">
            <div className="services-box">
                <div className="icon">
                    <i className={"fa " + elements.icon_class}/>
                </div>
                <h3 dangerouslySetInnerHTML={{__html: elements.title.rendered}}/>
                <p>{elements.home_content}</p>
                {/*<a href="#" className="details-btn"><i className="flaticon-arrow-pointing-to-right"/></a>*/}
            </div>
        </div>
    );
};

export default ServicesHomeCard;