import React, {useContext} from 'react';
import {apiGet, apiPost} from "../Tools/apiConfig";
import {useState} from 'react';
import {toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import {Link, useHistory} from "react-router-dom";
import {UserMeta} from "../Tools/UserMeta";
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";
import CryptoJS from "crypto-js";

const RegisterForm = () => {
        const {isLogin, setIsLogin} = useContext(AuthorizeDataContext);
        const history = useHistory();
        const toggleForm = () => {
            document.getElementById("toggleAccount").classList.toggle("active");
        };
        const LoginAfterReg = (username, password) => {
            let userAuthData = {
                username: username,
                password: password,
            }
            const baseURL = "/api/user/generate_auth_cookie/?username=" + username + "&password=" + password + "";
            try {
                const fetchData = apiGet(baseURL,).then((response) => {
                    window.localStorage.setItem('gd-auth', JSON.stringify(response.data));
                    window.localStorage.setItem('auth', CryptoJS.AES.encrypt(JSON.stringify(userAuthData), 'iotaciKey').toString())
                    UserMeta()
                    setIsLogin(response.data);
                    let now = new Date();
                    let time = now.getTime();
                    time += 3600 * 1000 * 24;
                    now.setTime(time);
                    document.cookie =
                        'auth=' + response.data[1] +
                        '; expires=' + now.toUTCString() +
                        '; path=*';
                    history.push(`/`);

                });
            } catch (err) {
                console.log(err)
            }
        };

        const registerUser = async (e) => {
            e.preventDefault();
            let userAuthData = {
                user_login: document.getElementsByName('email')[0].value,
                email: document.getElementsByName('email')[0].value,
                password: document.getElementsByName('passwordSignUp')[0].value,
                first_name: document.getElementsByName('Name')[0].value,
                last_name: document.getElementsByName('Family')[0].value,
                AUTH_KEY: 'AUTH_KEY'
            }
            if (userAuthData.user_login.length > 3 && userAuthData.email !== '' && userAuthData.password !== '') {
                let baseURL = '?rest_route=/simple-jwt-login/v1/users';
                try {
                    const fetchData = await apiPost(baseURL, userAuthData).then((response) => {
                        if (response.data.success) {
                            toast.success('You successfully registered', {
                                theme: "colored",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                            LoginAfterReg(userAuthData.user_login, userAuthData.password);
                            history.push(`/myaccount`);
                        } else {
                            toast.error("لطفا فیلدهای اجباری را وارد کنید", {
                                theme: "colored",
                                position: "top-center",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    });
                } catch (err) {
                    if (err.response.status === 400) {
                        toast.error(err.response.data.data.message)
                    } else {
                        toast.error('Please Check Later!')
                    }
                }
                // document.getElementById('registerForm').reset()
            } else {
                toast.error("لطفا فیلدهای اجباری را وارد کنید")
            }
        };


        return (

            <form name="registerForm" className="w-100" id='registerForm' onSubmit={registerUser}>
                <h2>Create an account</h2>
                <input type="text" name="Name" placeholder="First Name" required/>
                <input type="text" name="Family" placeholder="Last Name" required/>
                <input type="text" name="email" placeholder="Email Address" required/>
                <input type="password" name="passwordSignUp" placeholder="Create Password" required/>
                <input type="password" name="ConfirmPassword" placeholder="Confirm Password" required/>
                <div className="form-check ps-0">
                    <label className="form-check-label">
                        <input type="checkbox" required name="remember" className="form-input-styled me-2"/>
                        <span className="">By continuing, you're confirming that you've read our
                        <Link className="d-inline-block"
                              to="/terms">
                            Terms and conditions
                        </Link></span>
                    </label>
                </div>
                <button type="submit">Register</button>
                <p className="signup">
                    Already have an account?
                    <a href="#" onClick={toggleForm} className="d-inline-block"> Sign in.</a>
                </p>
            </form>

        );
    }
;

export default RegisterForm;