import ServicesHomeCard from "../Services/ServicesHomeCard";
import {Link} from "react-router-dom";
import {apiGet} from "../Tools/apiConfig";
import Skeleton from 'react-loading-skeleton';
import ServicesHomeCardSkeleton from "../Services/ServicesHomeCardSkeleton";
import {useQuery} from "react-query";

const Services = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Services"],
        async () => {
            let fields = 'title,content,better_featured_image,featured_media,slug,show_on_home,home_content,icon_class'
            const baseURL = "/wp-json/wp/v2/service?_fields=" + fields;
            return await apiGet(baseURL)
        }
    );

    if (isLoading || !isSuccess) {
        return (
            <section className="services-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-9 col-md-8 col-12">
                            <div className="section-title mx-0">
                                <h2 className="text-center text-lg-start">
                                    <Skeleton width={"40%"}/>
                                </h2>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                            <div className="latest__btn">
                                <Skeleton width={"40%"}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            Array.apply(0, Array(6)).map((item, i) =>
                                <ServicesHomeCardSkeleton key={i}/>
                            )
                        }
                    </div>
                </div>
            </section>
        )
    }

    return (
        <section className="services-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-8 col-12">
                        <div className="section-title mx-0">
                            <h2 className="text-center text-lg-start">
                                IoT Academy Services
                            </h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                        <div className="latest__btn">
                            <Link to="/services" className="primary-btn normal-btn">View All</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        data.data.map((item, i) =>
                            <ServicesHomeCard elements={item} key={i}/>
                        )
                    }
                    <div className="col-lg-4 col-md-6 col-12 mb-3">
                        <Link to="/services" className="services-box d-flex align-items-center flex-column justify-content-center">
                            {/*<div className="icon">*/}
                            {/*    <i className={"fa fa-chevron-right"}/>*/}
                            {/*</div>*/}
                            <h3>
                                Review All
                            </h3>
                            {/*<p>{elements.home_content}</p>*/}
                            <Link to="/services" className="details-btn"><i className="flaticon-arrow-pointing-to-right"/></Link>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );

};

export default Services;