import {useState, useContext, useEffect, useRef} from 'react';
import EventCard from "../../components/Event/EventCard";
import EventCardSkeleton from "../../components/Event/EventCardSkeleton";
import {ProductDataContext} from "../../context/ProductDataContext";
import {apiGet} from "../../components/Tools/apiConfig";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Keyboard} from "swiper";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";

const Event = () => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Events"],
        async () => {
            const baseURL = "/wp-json/wc/v3/products?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish";
            return await apiGet(baseURL)
        }
    );

    return (
        <section className="blog-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-12">
                        <div className="section-title mx-0">
                            <h2 className="text-center text-lg-start">IoT Academy Events</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-12 mb-4 mb-lg-0">
                        <div className="latest__btn">
                            <Link to="/event" className="primary-btn normal-btn">View All</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="eventCarousle position-relative">

                        <Swiper
                            modules={[Navigation, Keyboard]}
                            className={'swiper-container slider-zoom-slide slider-zoom-slide-dark black-move p-0'}
                            spaceBetween={30}
                            slidesPerView={1}
                            loop={true}
                            breakpoints={{
                                992: {
                                    slidesPerView: 3
                                },
                                991: {
                                    slidesPerView: 2
                                },
                                767: {
                                    slidesPerView: 2,
                                }
                            }}
                            keyboard={{enabled: true, onlyInViewport: true}}
                            navigation={{
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            }}
                        >
                            {
                                (isLoading || !isSuccess) ?
                                    Array.apply(0, Array(6)).map((item, i) =>
                                        <SwiperSlide className="mb30" key={i}>
                                            <EventCardSkeleton/>
                                        </SwiperSlide>
                                    ) :
                                    data.data.slice(0, 6).map((item, i) =>

                                        <SwiperSlide className="mb30" key={i}>
                                            <EventCard elements={item}/>
                                        </SwiperSlide>
                                    )
                            }
                        </Swiper>
                        <div ref={navigationPrevRef} className="navigationBtn navPrev">
                            <i className="fas fa-chevron-left"/>
                        </div>
                        <div ref={navigationNextRef} className="navigationBtn navNext">
                            <i className="fas fa-chevron-right"/>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default Event;