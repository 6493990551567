import {Link} from "react-router-dom";
import React from "react";

const EventCard = (props) => {
    let item = props.elements
    return (

        <div className="logo__carousel__item">
            <div className="single-blog-post">
                <Link to={"/event/" + item.slug}>
                    <img src={(item.images[0].length != 0) ? item.images[0].src : ''}
                         alt={item.name} className="lazy"/>
                </Link>
                <div className="blog-post-content">
                    <ul>
                        <li className="eventCatName">
                            <i className="fas fa-list"/>
                            <a href="#">{item.categories[0].name}
                            </a>
                        </li>
                        <li className="eventDate">
                            <i className="far fa-clock"/> Start Date:
                            {
                                new Date(item.meta_data.find(x => x.key === "event_date_start")?.value).toLocaleDateString('En-Us', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric'
                                })
                            }
                        </li>
                    </ul>
                    <h3>
                        <Link to={"/event/" + item.slug}>
                            {item.name}
                        </Link>
                    </h3>
                    <Link to={"/event/" + item.slug} className="read-more-btn">
                        Read More <i className="fas fa-angle-double-right"/>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default EventCard;