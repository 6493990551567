import {apiGet} from "../../components/Tools/apiConfig";
import {Link} from "react-router-dom";
import AccountLayout from '../../components/Account/AccountLayout'
import CryptoJS from "crypto-js";
import {useQuery} from "react-query";

const Ticket = () => {
    let bytes = CryptoJS.AES.decrypt(window.localStorage.getItem('auth'), 'iotaciKey');
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["TicketList"],
        async () => {
            const baseURL = "/wp-json/wpas-api/v1/tickets";
            return await apiGet(baseURL, {
                auth: {
                    username: decryptedData.username,
                    password: decryptedData.password
                }
            })
        }
    );
    const statusItem = (e) => {
        switch (e) {

            case 'queued':
                return 'bg-danger'
                break;
            case 'processing':
                return 'bg-warning'
                break;
            default:
                return 'bg-success'
        }
    }
    return (
        <AccountLayout>
            <div className="col-12 mb-sm-20">
                <div className="text-center mb-2">
                    <nav className="navbar navbar-expand-lg navbar-light bg-white">
                        <div className="container-fluid">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item  d-flex justify-content-between align-items-center p-2">
                                    <i className="fas fa-bars"/>
                                    <a className="nav-link" href="#">
                                        Ticket List</a>
                                </li>
                            </ul>
                            <div className="d-flex align-items-center">
                                <div className="dropdown">
                                    <Link className="btn btn-secondary" to="/ticket/add">
                                        Add New
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="list-group">
                    {
                        (!isLoading) ?
                            data.data.map((item, i) =>

                                <Link to={'/ticket/' + item.id}
                                      className="list-group-item list-group-item-action ticket-pill p-4"
                                      aria-current="true"
                                      key={i}
                                >
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{item.title.rendered}</h5>

                                        <small
                                            className={"badge rounded-pill text-uppercase " + statusItem(item.status)}>{item.status}</small>
                                    </div>
                                    <p className="mb-1 text-muted">{
                                        new Date(item.date).toLocaleDateString('En-Us', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })
                                    }</p>
                                    <small>See More
                                        <i className="fa fa-angle-right ms-1"/>
                                    </small>
                                </Link>
                            ) :
                            <div className="text-center">
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </AccountLayout>
    );
};

export default Ticket;