import {useState, useEffect} from 'react';

const Discovery = ({data}) => {
    const ItemsInterFace = {
        slug: "",
        better_featured_image: {
            source_url: ""
        },
        title: {
            rendered: ""
        },
        head_title: ""
    }
    const [first, setFirst] = useState(ItemsInterFace);
    const [second, setSecond] = useState(ItemsInterFace);
    const [third, setThird] = useState(ItemsInterFace);
    const [fourth, setFourth] = useState(ItemsInterFace);

    useEffect(() => {
        setFirst(data.filter(el => el.slug === "people-trained")[0])
        setSecond(data.filter(el => el.slug === "course-titles")[0])
        setThird(data.filter(el => el.slug === "event-helds")[0])
        setFourth(data.filter(el => el.slug === "industrial-consulting")[0])
    }, [first, second, third, fourth]);

    return (
        <section className="services spad bg-darkgray">
            <div className="container">
                <div className="counter__content">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-title mx-0">
                                <h2 className="text-center text-lg-start">IoT Academy Discovery</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-6">
                            <div className="counter__item row pb-5 p-lg-0">
                                <div className="col-12 col-lg-4 align-self-end px-lg-0">
                                    <div className="counter__item__image p-4 p-lg-0">
                                        <img src={first.better_featured_image.source_url}
                                             alt="@Html.Raw(Model.Parameter.ToList()[0].Value)"/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8 text-center text-lg-left px-lg-0">
                                    <div className="counter__item__number">
                                        <h2 className="count counter-count">
                                            {first.head_title}
                                        </h2>
                                    </div>
                                    <div className="counter__item__text">
                                        <h6 className="mb-0">
                                            {first.title.rendered}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                            <div className="counter__item row pb-5 p-lg-0">
                                <div className="col-12 col-lg-4 align-self-end px-lg-0">
                                    <div className="counter__item__image p-4 p-lg-0">
                                        <img src={second.better_featured_image.source_url}
                                             alt="@Html.Raw(Model.Parameter.ToList()[2].Value)"/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8 text-center text-lg-left px-lg-0">
                                    <div className="counter__item__number">
                                        <h2 className="count counter-count">
                                            {second.head_title}
                                        </h2>
                                    </div>
                                    <div className="counter__item__text">
                                        <h6 className="mb-0">
                                            {second.title.rendered}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                            <div className="counter__item row pb-5 p-lg-0">
                                <div className="col-12 col-lg-4 align-self-end px-lg-0">
                                    <div className="counter__item__image p-4 p-lg-0">
                                        <img src={third.better_featured_image.source_url}
                                             alt="@Html.Raw(Model.Parameter.ToList()[4].Value)"/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8 text-center text-lg-left px-lg-0">
                                    <div className="counter__item__number">
                                        <h2 className="count counter-count">
                                            {third.head_title}
                                        </h2>
                                    </div>
                                    <div className="counter__item__text">
                                        <h6 className="mb-0">
                                            {third.title.rendered}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-6">
                            <div className="counter__item row pb-5 p-lg-0">
                                <div className="col-12 col-lg-4 align-self-end px-lg-0">
                                    <div className="counter__item__image p-4 p-lg-0">
                                        <img src={fourth.better_featured_image.source_url}
                                             alt="@Html.Raw(Model.Parameter.ToList()[6].Value)"/>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-8 text-center text-lg-left px-lg-0">
                                    <div className="counter__item__number">
                                        <h2 className="count counter-count">
                                            {fourth.head_title}
                                        </h2>
                                    </div>
                                    <div className="counter__item__text">
                                        <h6 className="mb-0">
                                            {fourth.title.rendered}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Discovery;