import React from 'react'
import {Helmet} from "react-helmet";
import 'swiper/swiper.scss'
import ContactForm from "../../components/P2c/ContactForm"
import Document from "../../components/P2c/Document"
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import About from "../../components/Home/About";
import Event from "../../components/Home/Event";
import Discovery from "../../components/Home/Discovery";
import Partners from "../../components/Home/Partners";
import AboutSkeleton from "../../components/Home/AboutSkeleton";
import {useQuery} from "react-query";
import {apiGet} from "../../components/Tools/apiConfig";
const P2C = () => {
    SwiperCore.use([Autoplay, Navigation, Pagination])
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Ui_items"],
        () => {
            let fields = '_fields[]=title&_fields[]=content&_fields[]=better_featured_image&_fields[]=featured_media&_fields[]=slug&_fields[]=head_title&_fields[]=gallery'
            const baseURL = "/wp-json/wp/v2/ui_editor?" + fields;
            return apiGet(baseURL)
        }
    );

    return (
        <>
            <Helmet>
                <title>IoT Academy - ITU-P2C</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            ITU P2C
                        </h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>ITU-P2C</li>
                        </ul>
                    </div>
                </div>
            </div>
            {
                (isLoading || !isSuccess) ? <AboutSkeleton/> : <About data={data.data}/>
            }
            <Event/>
            {
                (isLoading || !isSuccess) ? <section className="services spad bg-darkgray"></section> :
                    <Discovery data={data.data}/>
            }
            {/*<Document/>*/}
            <Partners/>
            <ContactForm/>





        </>
    );
};

export default P2C;