import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import {apiGet} from "../Tools/apiConfig";
import {useQuery} from "react-query";

const BlogTags = ({count}) => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["BlogTags"],
        async () => {
            const baseURL = '/wp-json/wp/v2/tags?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';
            return await apiGet(baseURL)
        }
    );

    return (
        <div className="tagcloud">
            {
                (!isLoading) ?
                    data.data.sort((a, b) => b.count - a.count).slice(0, count ? count : 3).map((item, i) => {
                            let count = `<span className="tag-link-count"> (${item.count})</span>`
                            return <Link to={"/blog/tag/" + item.slug}>
                                <div dangerouslySetInnerHTML={{__html: item.name + count}}/>
                            </Link>
                        }
                    ) :
                    Array.apply(0, Array(count ? count : 3)).map((item, i) =>
                        <a key={i}>
                            <Skeleton width={'70px'}/>
                        </a>
                    )
            }
        </div>
    );
};

export default BlogTags;