import PartnersLogo from "../Common/PartnersLogo";
import PartnersLogoSkeleton from "../Common/PartnersLogoSkeleton";
import {apiGet} from "../Tools/apiConfig";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";

const Partners = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["partners"],
        async () => {
            const baseURL = '/wp-json/wp/v2/partner?_fields[]=title&_fields[]=featured_media&_fields[]=excerpt&_fields[]=better_featured_image';
            return await apiGet(baseURL)
        }
    );

    return (
        <section className="testimonial spad set-bg partneri">
            <div className="container">
                <div className="row">

                    <div className="col-lg-9 col-md-8 col-12">
                        <div className="section-title mx-0">
                            <h2 className="text-center text-lg-start">IoT Academy Partenrs</h2>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-12 mb-4 mb-lg-0">
                        <div className="latest__btn">
                            <Link to="/about" className="primary-btn normal-btn">View All</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo-partners pb-3">
                            {
                                (isLoading || !isSuccess) ?
                                    <PartnersLogoSkeleton/> :
                                    <PartnersLogo elements={data.data}/>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default Partners;