import {useState, useEffect} from 'react';
import BlogCard from "../../components/Blog/BlogCard";
import {Helmet} from "react-helmet";
import BlogCardSkeleton from "../../components/Blog/BlogCardSkeleton";
import {apiGet} from "../../components/Tools/apiConfig";
import {useHistory} from "react-router-dom";
import {useQuery} from "react-query";

export default function BlogList() {
    const [itemEnd, setItemEnd] = useState(6);
    const [itemStart, setItemStart] = useState(0);
    const [blogSearchItems, setBlogSearchItems] = useState([]);
    const [blogCategoryItems, setBlogCategoryItems] = useState([])
    const [onSearchMethod, setOnSearchMethod] = useState(false);
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["BlogList"],
        async () => {
            const baseCatURL = '/wp-json/wp/v2/categories?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';
            await apiGet(baseCatURL).then(r => setBlogCategoryItems(r.data))
            const baseURL = "?rest_route=/wp/v2/posts&_embed&per_page=100";
            return await apiGet(baseURL)
        }
    );

    const history = useHistory();

    const loadMore = () => {
        setItemEnd(itemEnd + 6);
    }

    const filterBySearch = () => {

        if (isLoading) {
            let word = document.getElementById('searchBlog').value;

            if (word.length > 0) {
                setOnSearchMethod(true);
                let filteredItems = [].concat(data.data).filter((item) => {
                    return item.title.rendered.toLowerCase().match(word.toLowerCase());
                })
                setBlogSearchItems(filteredItems);
            } else {
                setOnSearchMethod(false);
            }
        }
    }

    const changeCatRoute = (x) => {
        blogCategoryItems.filter((item) => {
            if (item.id === Number(x.target.value)) {
                history.push(`/blog/category/${item.slug}`);
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>IoT Academy - News</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            Internet of Things News
                        </h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>News</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">
                    <div className="row mb-5">
                        <div className="col-md-6">
                            <aside className="widget-area ev" id="secondary">
                                <section className="widget widget_search">
                                    <form className="search-form">
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="text" name="searchBlog" id="searchBlog"
                                                   className="search-field"
                                                   placeholder="Search..."
                                                   onChange={filterBySearch}
                                            />
                                        </label>
                                        <button id="searchItemEvent" type="button"><i className="fas fa-search"/>
                                        </button>
                                    </form>
                                </section>
                            </aside>
                        </div>
                        {/*<div className="clearfix"/>*/}
                        <div className="col-md-6">
                            <section className="widget widget_categories ev align-items-center">
                                <h5 className="widget-title ev me-2 pb-2 pb-lg-0 mb-0 d-none d-md-block">Categories: </h5>
                                <select placeholder="select category" className="form-select rounded-0 blogSelect w-100"
                                        name="scategoryNameBlog"
                                        onChange={changeCatRoute}>
                                    <option value="" selected disabled>Select Category</option>
                                    {
                                        blogCategoryItems.map((item, index) => {
                                            return (
                                                <option value={item.id} key={index}>
                                                    {item.name}
                                                </option>
                                            )
                                        })
                                    }

                                </select>

                            </section>
                        </div>
                    </div>
                    <div className="row">
                        {
                            (!onSearchMethod) ?
                                (!isLoading) ?
                                    data.data.slice(itemStart, itemEnd).map((item, i) =>
                                        <div key={i} className="col-lg-4 col-md-6 mb30">
                                            <BlogCard elements={item}/>
                                        </div>
                                    ) :
                                    Array.apply(0, Array(6)).map((item, i) =>
                                        <div key={i} className="col-lg-4 col-md-6 mb30">
                                            <BlogCardSkeleton/>
                                        </div>
                                    )
                                : blogSearchItems.slice(itemStart, itemEnd).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <BlogCard elements={item}/>
                                    </div>
                                )
                        }
                    </div>

                    <div className="spacer-single"/>

                    <ul className="pagination mt-5">

                        {
                            (!isLoading) ?
                                (data.data.length > 6) ?
                                    <button type="button" className="btn btn-dark mx-auto"
                                            onClick={loadMore}>
                                        Load More
                                    </button> : null : null
                        }

                    </ul>

                </div>
            </section>
        </>
    )
}
