import {useState} from 'react';
import BlogCard from "../../components/Blog/BlogCard";
import {Helmet} from "react-helmet";
import BlogCardSkeleton from "../../components/Blog/BlogCardSkeleton";
import {apiGet} from "../../components/Tools/apiConfig";
import {Link, useParams} from "react-router-dom";
import {useQuery} from "react-query";

export default function BlogListByTag() {
    const [itemEnd, setItemEnd] = useState(6);
    const [pageNumber, setPageNumber] = useState(1);
    const [itemStart, setItemStart] = useState(0);
    const [activePage, setActivePage] = useState(1);
    const {slug} = useParams()

    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["BlogListTags" + slug],
        async () => {
            const baseCatURL = '/wp-json/wp/v2/tags?_fields[]=name&_fields[]=slug&_fields[]=count&_fields[]=id';
            const tags = [];
            await apiGet(baseCatURL).then(r => tags.push(...r.data))
            const baseURL = "?rest_route=/wp/v2/posts&_embed&per_page=100";
            return await apiGet(baseURL).then(re => {
                let this_page_id = tags.find(el=> el.slug = slug).id
                let dd = re.data.filter(el => el.tags.find(e => e === this_page_id))
                setPageNumber(Math.floor(Number(dd.length) / itemEnd) + 1)
                return dd
            })
        }
    );

    const goToPage = e => {
        window.scrollTo(0, 0);
        let gtNumb = parseInt(e.target.attributes['data-gt'].value);
        let iStart = 0;
        let iEnd = 6;
        let newEnd = gtNumb * iEnd;
        let newStart = newEnd - iEnd;
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const prevPage = () => {
        window.scrollTo(0, 0);
        let gtNumb = (activePage > 1) ? activePage - 1 : activePage;
        let iStart = 0;
        let iEnd = 6;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const nextPage = () => {
        window.scrollTo(0, 0);
        let gtNumb = (activePage !== pageNumber) ? activePage + 1 : activePage;
        let iStart = 0;
        let iEnd = 6;
        let newEnd = gtNumb * iEnd //4
        let newStart = newEnd - iEnd
        setItemStart(newStart);
        setItemEnd(newEnd);
        setActivePage(gtNumb);
    }

    const loadMore = () => {
        setItemEnd(itemEnd + 6);
    }
    return (
        <>
            <Helmet>
                <title>IoT Academy - News</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            Internet of Things News
                        </h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>Blog Tags</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">
                    <div className="row">
                        {
                            (!isLoading) ?
                                data.length === 0 ?
                                    <>
                                        <div className="text-center">
                                            No Item Found! 😢
                                        </div>
                                        <Link to="/" className="mt-3 d-block text-center">
                                            <button className="btn btn-secondary">
                                                Back to Home
                                            </button>
                                        </Link>
                                    </>
                                    :
                                    data.slice(itemStart, itemEnd).map((item, i) =>
                                        <div key={i} className="col-lg-4 col-md-6 mb30">
                                            <BlogCard elements={item}/>
                                        </div>
                                    ) :
                                Array.apply(0, Array(6)).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <BlogCardSkeleton/>
                                    </div>
                                )
                        }

                    </div>
                    <div className="spacer-single"/>

                    <ul className="pagination mt-5">

                        {
                            (!isLoading) ?
                                (data.length > 6) ?
                                    <button type="button" className="btn btn-dark mx-auto"
                                            onClick={loadMore}>
                                        Load More
                                    </button> : null : null
                        }

                    </ul>
                </div>
            </section>
        </>
    )
}
