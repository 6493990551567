import React from 'react'

import {Link} from "react-router-dom";
import NewsLetter from "../Tools/NewsLetter";

export default class Footer extends React.Component {
    render() {
        return (
            <footer className="footer set-bg">
                <div className="container">
                    <div className="footer__top">
                        <div className="row">
                            <div className="col-lg-8 col-md-6">
                                <div className="footer__top__text">
                                    <h2>Ready To Work With Us?</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="footer__top__newslatter">
                                    <NewsLetter/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="footer__address">
                                <h6>Get In Touch</h6>
                                <p>Address-1: Building 1, University of Tehran Science and Technology Park, 16th Street,
                                    North Kargar St., Tehran, Iran.</p>
                                <ul>
                                    <li><a href="mailto:info@iotaci.com">info@iotaci.com</a></li>
                                    <li><a href="tel:+982186081025">(+98) 2186081025</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-2 offset-lg-1 col-md-3 col-sm-6">
                            <div className="footer__widget">
                                <h6>Company</h6>
                                <ul className="mb-2">
                                    <li><Link to="/about">About Us</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                    <li><Link to="/faq">FAQs</Link></li>
                                </ul>
                                <div>
                                    <a referrerPolicy="origin" target="_blank"
                                       href="https://trustseal.enamad.ir/?id=322529&amp;Code=xlkJ8ryUoqFXwVcvMrRt">
                                        <img
                                            referrerPolicy="origin"
                                            src="https://Trustseal.eNamad.ir/logo.aspx?id=322529&amp;Code=xlkJ8ryUoqFXwVcvMrRt"
                                            alt="Enamad" style={{cursor: "pointer"}} id="xlkJ8ryUoqFXwVcvMrRt"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6">
                            <div className="footer__widget">
                                <h6>Services</h6>
                                <ul className="mb-2">
                                    <li><Link to="/services">Services</Link></li>
                                    <li><Link to="/event">Event</Link></li>
                                    <li><Link to="/blog">News</Link></li>
                                </ul>

                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footer__about text-lg-center">
                                <div className="footer__logo">
                                    <a href="/">
                                        <img src="/assets/img/Site/IoT-Academy-logo-white.png" alt=""
                                             width="150px"/>
                                    </a>
                                </div>
                                <div className="footer__social mt-4">
                                    <a href="https://www.telegram.me/IotAcademy" target="_blank"><i
                                        className="fa fa-paper-plane"/></a>
                                    <a href="https://www.instagram.com/IotAcademy" target="_blank"><i
                                        className="fab fa-instagram"/></a>
                                    <a href="https://www.linkedin.com/company/iotacademy/" target="_blank"><i
                                        className="fab fa-linkedin"/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-lg-8 col-md-7">
                                <div className="copyright__text">
                                    <p>
                                        Copyright ©
                                        {
                                            new Date().getFullYear()
                                        }
                                        &nbsp;IoT Academy Inc. All rights reserved.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className="copyright__widget">
                                    <Link to="/terms">Terms And Conditions</Link>
                                    <Link to="/policy">Privacy Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}