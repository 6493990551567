import {createContext, useState} from 'react';
export const DucumentDataContext = createContext(undefined);

export const DocumentDataProvider = (props) => {
    const [ducumentItems, setDucumentItems] = useState([])
    return (
        <DucumentDataContext.Provider value={{ducumentItems, setDucumentItems}}>
            {props.children}
        </DucumentDataContext.Provider>
    );
};
