import {Helmet} from "react-helmet";

import 'swiper/swiper.scss'

import Slider from "../../components/Home/Slider";
import Blog from "../../components/Home/Blog";
import Partners from "../../components/Home/Partners";
import Services from "../../components/Home/Services";
import About from "../../components/Home/About";
import Banner from "../../components/Home/Banner";
import Event from "../../components/Home/Event";
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import Discovery from "../../components/Home/Discovery";
import {ParallaxProvider} from 'react-scroll-parallax';
import {useQuery} from "react-query";
import {apiGet} from "../../components/Tools/apiConfig";
import AboutSkeleton from "../../components/Home/AboutSkeleton";

const IndexPage = () => {
    SwiperCore.use([Autoplay, Navigation, Pagination])
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Ui_items"],
        () => {
            let fields = '_fields[]=title&_fields[]=content&_fields[]=better_featured_image&_fields[]=featured_media&_fields[]=slug&_fields[]=head_title&_fields[]=gallery'
            const baseURL = "/wp-json/wp/v2/ui_editor?" + fields;
            return apiGet(baseURL)
        }
    );

    return (
        <>
            <Helmet>
                <title>IoT Academy</title>
            </Helmet>
            <Slider/>
            {
                (isLoading || !isSuccess) ? <AboutSkeleton/> : <About data={data.data}/>
            }
            <ParallaxProvider scrollAxis="vertical">
                <Banner/>
            </ParallaxProvider>
            <Services/>
            <Event/>
            {
                (isLoading || !isSuccess) ? <section className="services spad bg-darkgray"></section> :
                    <Discovery data={data.data}/>
            }
            <Blog/>
            <Partners/>
        </>
    );

};

export default IndexPage;