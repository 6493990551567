import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {apiPost} from "./apiConfig";
import {toast} from "react-toastify";

const NewsLetter = () => {
    const [abs, setAbs] = useState(0)
    const NewsLetterSubmit = (e) => {
        e.preventDefault();
        let mailId = document.getElementsByName('emailId')[0].value;
        if (mailId !== "") {
            let formdata = new FormData();
            formdata.append("your-email", mailId);

            const baseURL = "/wp-json/contact-form-7/v1/contact-forms/307/feedback"
            apiPost(baseURL, formdata)
                .then(response => {
                    if (response.data.status === "mail_sent") {
                        setAbs(1);
                        toast.success("ایمیل شما با موفقیت ثبت شد")
                        document.getElementById('form_subscribe').reset();
                    } else {
                        toast.error("خطا! لطفا ایمیل خود را صحیح وارد کنید")
                    }

                }).catch(() => {
                toast.error("خطا! لطفا اتصال اینترنت خود را بررسی کنید")
            })

        }
    }
    const submitSubscribe = (e) => {
        e.preventDefault();
        NewsLetterSubmit(e);
    }
    return (

        <form id="form_subscribe" onSubmit={NewsLetterSubmit}>
            <input type="text" placeholder="Enter your email..." id="txt_subscribe"
                   data-minemail-com="0" name="emailId"/>

            <button onClick={submitSubscribe} type="button"><i className="fa fa-paper-plane"/></button>
        </form>
    );

};

export default NewsLetter;