import React from 'react';

const FaqItem = ({element}) => {
    const showItem = (e) => {
        console.log(e.currentTarget.nextElementSibling);
        e.currentTarget.nextElementSibling.classList.toggle('show');
        e.currentTarget.classList.toggle('active');
    }


    return (
        <li className="accordion-item">
            {/*// active*/}
            <a href={void(0)} className="accordion-title" onClick={showItem}>
                <i className="flaticon-add"/>
                <div dangerouslySetInnerHTML={{__html: element.title.rendered}}/>
            </a>
            <div className="accordion-content" dangerouslySetInnerHTML={{__html: element.content.rendered}}/>
            {/*// display block*/}
        </li>
    );
};

export default FaqItem;