import AccountLayout from '../../components/Account/AccountLayout'
import {useContext, useEffect, useState} from "react";
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";

const MyAccount = () => {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext)
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        if (typeof isLogin.status !== "undefined") {
            setIsLoading(true)
        }
    }, [isLogin])

    return (
        <AccountLayout>
            <div className="card mb-4">
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-3">
                            <p className="mb-0">Full Name</p>
                        </div>
                        <div className="col-sm-9">
                            <p className="text-muted mb-0">
                                {
                                    (!isLoading) ?
                                        <i className="fa fa-spinner fa-spin"/>
                                        :

                                        isLogin.user.firstname + ' ' + isLogin.user.lastname
                                }
                            </p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-3">
                            <p className="mb-0">Email</p>
                        </div>
                        <div className="col-sm-9">
                            <p className="text-muted mb-0">
                                {
                                    (!isLoading) ?
                                        <i className="fa fa-spinner fa-spin"/>
                                        :
                                        isLogin.user.email
                                }
                            </p>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col-sm-3">
                            <p className="mb-0">Username</p>
                        </div>
                        <div className="col-sm-9">
                            <p className="text-muted mb-0">
                                {
                                    (!isLoading) ?
                                        <i className="fa fa-spinner fa-spin"/>
                                        :
                                        isLogin.user.username
                                }
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </AccountLayout>
    );
};

export default MyAccount;