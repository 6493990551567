import React, {useContext, useEffect, useState} from 'react';
import {apiGet, apiPost} from "../../components/Tools/apiConfig";
import {useParams} from "react-router-dom";
import {TicketsDataContext} from "../../context/TicketDataContext";
import {Helmet} from "react-helmet";
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";
import {toast} from "react-toastify";
import CryptoJS from "crypto-js";
import AccountLayout from "../../components/Account/AccountLayout";
import Skeleton from "react-loading-skeleton";
import {useQuery} from "react-query";

const TicketDetail = () => {
        const {isLogin} = useContext(AuthorizeDataContext);
        const {slug} = useParams()
        const [TicketDetails, SetTicketDetails] = useState([]);
        let bytes = CryptoJS.AES.decrypt(window.localStorage.getItem('auth'), 'iotaciKey');
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
            ["TicketDetail" + slug],
            async () => {
                const baseURLTicketReplay = "/wp-json/wpas-api/v1/tickets/" + slug + "/replies";
                await apiGet(baseURLTicketReplay, {
                    auth: {
                        username: decryptedData.username,
                        password: decryptedData.password
                    }
                }).then(r => SetTicketDetails(...r.data))
                const baseURL = "/wp-json/wpas-api/v1/tickets";
                return await apiGet(baseURL, {
                    auth: {
                        username: decryptedData.username,
                        password: decryptedData.password
                    }
                })
            });
        const replyTicket = async (e) => {
            e.preventDefault();
            const baseURL = "/wp-json/wpas-api/v1/tickets/" + slug + "/replies";
            let option = {
                content: document.getElementsByName('ticketReply')[0].value
            }
            try {
                const fetchData = await apiPost(baseURL, option, {
                    auth: {
                        username: decryptedData.username,
                        password: decryptedData.password
                    }
                }).then((response) => {
                    if (response.data) {
                        toast.success('Your Message Sent!');
                        refetch();
                    } else {
                        toast.error('Your Message Sent!');
                    }
                });
            } catch (err) {
                console.error(err);
            }
        };

        const statusItem = (e) => {
            switch (e) {

                case 'queued':
                    return 'bg-danger'
                    break;
                case 'processing':
                    return 'bg-warning'
                    break;
                default:
                    return 'bg-success'
            }
        }

        const statusChat = (e) => {
            switch (e) {
                case 'read':
                    return 'bg-success'
                    break;
                case 'unread':
                    return 'bg-warning'
                    break;
                default:
                    return 'bg-success'
            }
        }

        return (
            <AccountLayout>
                <Helmet>
                    <title>IoT Academy - Ticket</title>
                </Helmet>
                <section>
                    {
                        !isLoading ?
                            data.data.filter(el => el.id === Number(slug)).map((item, i) =>
                                <div className="list-group-item list-group-item-action mb-3" key={i}
                                     aria-current="true">
                                    <div className="d-flex w-100 justify-content-between">
                                        <h5 className="mb-1">{item.title.rendered}</h5>
                                        <small
                                            className={"badge p-2 rounded-pill text-uppercase " + statusItem(item.status)}>{item.status}</small>
                                    </div>
                                    <p className="mb-1 text-muted">{
                                        new Date(item.date).toLocaleDateString('En-Us', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric'
                                        })
                                    }</p>
                                    <div dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                </div>
                            ) :
                            <div className="list-group-item list-group-item-action mb-3"
                                 aria-current="true">
                                <div className="d-flex w-100 justify-content-between">
                                    <Skeleton width="80%"/>
                                    <Skeleton width="20%"/>
                                </div>
                                <Skeleton width="20%"/>
                                <Skeleton count={4}/>
                            </div>
                    }

                    {
                        (!isLoading) ?
                            (TicketDetails.length > 0) ?
                                TicketDetails.map((item, i) =>
                                    (item.author !== isLogin.user.id) ?
                                        <div className="row g-0" key={i}>
                                            <div className="mb-2 col-12 col-md-8 col-lg-6">
                                                <div className="list-group-item list-group-item-action"
                                                     aria-current="true">
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <p className="mb-1 text-muted">{
                                                            new Date(item.date).toLocaleDateString('En-Us', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric'
                                                            })
                                                        }
                                                        </p>
                                                        <small
                                                            className={"badge p-2 rounded-pill text-uppercase " + statusChat(item.status)}>{item.status}</small>
                                                    </div>

                                                    <div dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="row g-0">
                                            <div className="mb-2 col-12 col-md-8 col-lg-6 offset-0 offset-md-2 offset-lg-6">
                                                <div className="list-group-item list-group-item-action bg-darkgray"
                                                     aria-current="true">
                                                    <div className="d-flex w-100 justify-content-between">
                                                        <p className="mb-1 text-muted">{
                                                            new Date(item.date).toLocaleDateString('En-Us', {
                                                                year: 'numeric',
                                                                month: 'long',
                                                                day: 'numeric'
                                                            })
                                                        }
                                                        </p>                                                <small
                                                        className={"badge p-2 rounded-pill text-uppercase " + statusChat(item.status)}>{item.status}</small>
                                                    </div>


                                                    <div dangerouslySetInnerHTML={{__html: item.content.rendered}}/>

                                                </div>
                                            </div>
                                        </div>
                                ) :
                                <div className="alert alert-light text-center">
                                    <p>No Response yet!</p>
                                </div>
                            : <div className="text-center">
                                <div className="spinner-border text-dark" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                    }

                    <div className="mt-5">
                        <form onSubmit={replyTicket} className="bg-white">

                        <textarea name="ticketReply" placeholder="Your Message Description"
                                  className="form-control w-100 h-auto p-4"
                                  rows="4"/>
                            <div className="text-center">
                                <button className="btn btn-dark my-4">Send <em className="fa fa-paper-plane"/></button>
                            </div>
                        </form>
                    </div>
                </section>
            </AccountLayout>
        );
    }
;

export default TicketDetail;