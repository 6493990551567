import React from 'react';
import RegisterForm from "../../components/Account/RegisterForm";
import {Link} from "react-router-dom";

const Register = () => {
    return (

        <section className="full-height relative no-top no-bottom vertical-center"
                 style={{background: "url(/assets/images/background/16.jpg) center top no-repeat", backgroundSize: "cover"}} data-stellar-background-ratio=".5">
            <div className="overlay-gradient t50">
                <div className="center-y relative">
                    <div className="container">
                        <div className="row align-items-center">

                            <div className="col-lg-4 offset-lg-2 wow fadeIn animated order-2 order-lg-1" data-wow-delay=".5s"
                                 style={{visibility: "visible", animationDelay: "0.5s", animationName: "fadeIn"}}>
                                <div className="box-rounded padding40" data-bgcolor="#ffffff"
                                     style={{backgroundColor: "rgb(255, 255, 255)"}}>
                                    <h3 className="mb10">ثبت نام</h3>
                                    <p>
                                        حساب کاربری دارید؟ <Link to="/login">وارد
                                        <span/>
                                    </Link>
                                        &nbsp;شوید.
                                    </p>
                                    <RegisterForm/>
                                </div>
                            </div>

                            <div className="col-lg-5 wow fadeInRight animated order-1 order-lg-2" data-wow-delay=".5s"
                                 style={{visibility: "visible", animationDelay: "0.5s", animationName: "fadeInRight"}}>
                                <div className="spacer-10"/>
                                <h1>واژه نامه اینترنت اشیا</h1>
                                <p className="lead">  سرویس رایگان IoT Dictionary واژه نامه تخصصی در حوزه اینترنت اشیا با قابلیت  جستجوی سریع، هوشمند و تخصصی<br/> جهت دسترسی به قابلیت و حق دسترسی بیشتر عضو واژه نامه شوید. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Register;