import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {Link} from "react-router-dom";

const EventCardSkeleton = () => {

    function hightCalculation() {
        if (window.innerWidth > 991) {
            return (1320 / 3 - 30) * 2 / 3 + 'px'
        } else if (window.innerWidth < 992 && window.innerWidth > 425) {
            return 720 / 2 - 30 + 'px'
        } else {
            return window.innerWidth - 30 + 'px'
        }
    }


    return (


        <div className="logo__carousel__item">
            <div className="single-blog-post">
                <div>
                    <Skeleton height={hightCalculation()} width={'100%'}/>
                </div>
                <div className="blog-post-content">
                    <ul>
                        <li className="eventCatName">
                            <a href="#">
                                <Skeleton width={'12%'}/>
                            </a>
                        </li>
                        <li className="eventDate">
                            <Skeleton width={'20%'}/>
                        </li>
                    </ul>
                    <h3>
                        <div>
                            <Skeleton width={'90%'}/>
                        </div>
                    </h3>
                    <div className="read-more-btn">
                        <Skeleton width={'40%'}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventCardSkeleton;