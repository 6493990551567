import {Link, useHistory} from "react-router-dom";
import {apiGet, apiPost} from "../Tools/apiConfig";
import {toast} from "react-toastify";
import React, {useContext} from "react";
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";
import 'react-toastify/dist/ReactToastify.css';
import {UserMeta} from "../Tools/UserMeta";
import CryptoJS from "crypto-js";

const LoginForm = () => {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext);
    const history = useHistory();
    let ciphertext = CryptoJS.AES.encrypt(JSON.stringify('data'), 'iotaciKey').toString();

    const toggleForm = () => {
        document.getElementById("toggleAccount").classList.toggle("active");
    };

    const loginUser = async (e) => {
        e.preventDefault()
        let userAuthData = {
            username: document.getElementsByName('username')[0].value,
            password: document.getElementsByName('password')[0].value,
            // privacy: document.getElementById('check1').checked
        }

        if (userAuthData.username != '' && userAuthData.password != '') {
            const baseURL = "/api/user/generate_auth_cookie/?username=" + userAuthData.username + "&password=" + userAuthData.password + "";
            try {
                const fetchData = apiGet(baseURL, userAuthData).then((response) => {
                    if (response.data.status === 'error') {
                        toast.error('Please check your username and password', {
                            theme: "colored",
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        window.localStorage.setItem('gd-auth', JSON.stringify(response.data));
                        window.localStorage.setItem('auth', CryptoJS.AES.encrypt(JSON.stringify(userAuthData), 'iotaciKey').toString())
                        toast.success('Login successfully', {
                            theme: "colored",
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });

                        UserMeta()
                        setIsLogin(response.data);
                        let now = new Date();
                        let time = now.getTime();
                        time += 3600 * 1000 * 24;
                        now.setTime(time);
                        document.cookie =
                            'auth=' + response.data[1] +
                            '; expires=' + now.toUTCString() +
                            '; path=*';
                        history.push(`/`);
                    }
                });
            } catch (err) {
                console.error(err);
            }
        }
    }

    return (

        <form method="post" className="w-100" id='loginForm' onSubmit={loginUser}>
            <h2>Sign In</h2>
            <input type="text" name="username" className="form-control" placeholder="Email address" required/>
            <input type="password" name="password" className="form-control" placeholder="Password" required/>
            <div className="d-log">
                <button type="submit">Login</button>

                {/*<div className="form-check mb-0">*/}
                {/*    <label className="form-check-label">*/}
                {/*        <input type="checkbox" name="remember" className="form-input-checkbox" checked/>*/}
                {/*            Remember*/}
                {/*    </label>*/}
                {/*</div>*/}

            </div>

            <p className="signup">
                <Link to="/resetpassword" className="">Forgot password?</Link>
            </p>

            <p className="signup">
                Don't have an account ?
                <a href="#" onClick={toggleForm}>Sign Up.</a><br/>

            </p>
        </form>
    );
};

export default LoginForm;