import React, {useContext, useState} from 'react'
import {Link} from "react-router-dom";
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";
import {Logout} from "../Account/Logout";
import {useLocation} from "react-router";

const AppHeader = () => {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext)
    const [lang, setLang] = useState('en')
    let location = useLocation()

    const menuItems = [
        {name: 'Home', link: '/', active: (location.pathname === '/')},
        {name: 'Services', link: '/services', active: (location.pathname === '/services')},
        {name: 'Events', link: '/event', active: (location.pathname === '/event')},
        {name: 'Blog', link: '/blog', active: (location.pathname === '/blog')},
        {name: 'P2C', link: '/ITU-P2C', active: (location.pathname === '/ITU-SDG-Praogram')},
        {name: 'Contact', link: '/contact', active: (location.pathname === '/contact')},
        {name: 'About', link: '/about', active: (location.pathname === '/about')},
    ]

    const closeMenu = () => {
        document.getElementsByClassName('menu-toggler')[0].click()
    }

    const logMeOut = () => {
        Logout()
    }

    return (
        <header className="header-area header-style-two">
            <div className="navbar-area">
                <div className="iotaci-nav">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <Link className="navbar-brand" to="/">
                                <img src="/assets/img/Site/IoT-Academy-logo-white.png" alt="iotaci.com"
                                     width="100px" className="img-white"/>
                                <img src="/assets/img/Site/IoTAcademy-logo.png" alt="iotaci.com"
                                     width="100px" className="img-colorful"/>
                            </Link>
                            <button className="navbar-toggler menu-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"/>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    {
                                        menuItems.map((item, i) =>
                                            <li key={i} className={`nav-item ${(item.active) ? 'active' : ''}`}
                                            >
                                                <Link to={item.link} className="nav-link"
                                                      onClick={closeMenu}>{item.name}</Link>
                                            </li>)
                                    }

                                </ul>
                                <hr className="m-0" size="5"/>
                                <div className="others-options">
                                    <ul className="navbar-nav">

                                        {
                                            (isLogin.status === "ok") ?

                                                <li className="nav-item withDrop">
                                                    <a className="nav-link">
                                                        Hello {isLogin.user.displayname}
                                                        <i className="fas fa-chevron-down ms-1"/>
                                                    </a>

                                                    <ul className="dropdown-menu">

                                                        <li className="nav-item">
                                                            <Link to="/myaccount" className="nav-link"
                                                                  onClick={closeMenu}>
                                                                <i className="icon icon-user pl-2"/>
                                                                My Profile
                                                            </Link>
                                                        </li>

                                                        <li className="nav-item">
                                                            <Link to="#" onClick={logMeOut} className="nav-link">
                                                                <i className="icon icon-logout pl-2"/>
                                                                Logout
                                                            </Link>
                                                        </li>

                                                    </ul>

                                                </li> :
                                                <li className="nav-item">
                                                    <Link className="nav-link" to="/login">
                                                        Sign in
                                                    </Link>
                                                </li>
                                        }
                                        <li className="nav-item">
                                            <div className="dropdown">
                                                <a className="nav-link" href="#" role="button"
                                                   onClick={() => window.localStorage.setItem('lang', 'en')}
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                    EN
                                                </a>

                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a href="/ge" className="dropdown-item"
                                                           onClick={() => window.localStorage.setItem('lang', 'ge')}>
                                                            GE
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="/ar"
                                                           onClick={() => window.localStorage.setItem('lang', 'ar')}>
                                                           AR
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>

    );
};

export default AppHeader;
