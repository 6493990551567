import Skeleton from 'react-loading-skeleton';

const ServicesCardSkeleton = () => {
    return (
        <div className="col-12 mb-3">
            <div className="services-box">
                <Skeleton circle={true} height={75} width={75} />
                <h3>
                    <Skeleton width={"60%"} />
                </h3>
                <div>
                    <Skeleton count={6} />
                </div>
            </div>
        </div>
    );
};

export default ServicesCardSkeleton;