import AccountLayout from "../../components/Account/AccountLayout";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import {apiGet} from "../../components/Tools/apiConfig";
import CryptoJS from "crypto-js";

const OrderHistory = () => {
        let bytes = CryptoJS.AES.decrypt(window.localStorage.getItem('auth'), 'iotaciKey');
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
            ["OrderHistory"],
            async () => {
                const baseURL = "/wp-json/wc/v3/orders?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&customer_id=1&order=asc";
                return await apiGet(baseURL)
            }
        );

        const statusItem = (e) => {
            switch (e) {
                case 'failed':
                    return 'bg-danger'
                    break;
                case 'pending':
                    return 'bg-warning'
                    break;
                default:
                    return 'bg-success'
            }
        }

        return (
            <AccountLayout>
                <div className="col-12 mb-sm-20">
                    <div className="text-center mb-2">
                        <nav className="navbar navbar-expand-lg navbar-light bg-white">
                            <div className="container-fluid">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                    <li className="nav-item  d-flex justify-content-between align-items-center p-2">
                                        <i className="fas fa-bars"/>
                                        <a className="nav-link" href="#">
                                            Order History</a>
                                    </li>
                                </ul>

                            </div>
                        </nav>
                    </div>
                    <div className="list-group">
                        {
                            (!isLoading) ?
                                data.data.map((item, i) => {
                                    return (
                                        <div key={i} className="list-group-item list-group-item-action ticket-pill p-4">
                                            <div className="d-flex w-100 justify-content-between">
                                                <div className="w-50">
                                                    <img src={item.line_items[0].image.src} width={'50px'}
                                                         alt={item.line_items[0].name}/>
                                                    <span className="ms-2"
                                                          title={item.line_items[0].name}>{item.line_items[0].name.length > 50 ? item.line_items[0].name.substring(50) + '...' : item.line_items[0].name}</span>
                                                </div>
                                                <div>
                                                    {item.total} {item.currency}
                                                </div>
                                                <div className="mb-1 text-muted">{
                                                    new Date(item.date_created).toLocaleDateString('En-Us', {
                                                        year: '2-digit',
                                                        month: '2-digit',
                                                        day: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit',
                                                        second: '2-digit'
                                                    })
                                                }</div>
                                                <div>
                                                    <small
                                                        className={"badge rounded-pill text-uppercase " + statusItem(item.status)}>{item.status}</small>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) :
                                <div className="text-center">
                                    <div className="spinner-border text-dark" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </AccountLayout>
        );
    }
;

export default OrderHistory;