import {createContext, useState} from 'react';
import {UserMeta} from "../components/Tools/UserMeta";
export const AuthorizeDataContext = createContext();

export const AuthorizeDataProvider = (props) => {
    const [isLogin, setIsLogin] = useState({})
    UserMeta()

    return (
        <AuthorizeDataContext.Provider value={{isLogin, setIsLogin}}>
            {props.children}
        </AuthorizeDataContext.Provider>
    );
};