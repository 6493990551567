import React, {useState, useContext, useEffect} from 'react';
import {Helmet} from "react-helmet";
import {apiGet} from "../../components/Tools/apiConfig";
import {Link, useParams} from "react-router-dom";
import EventCard from "../../components/Event/EventCard";
import EventCardSkeleton from "../../components/Event/EventCardSkeleton";
import {useQuery} from "react-query";

export default function EventListByCategory() {
    const [itemEnd, setItemEnd] = useState(6);
    const [itemStart] = useState(0);
    const [productSearchItems, setEventSearchItems] = useState([]);
    const [onSearchMethod, setOnSearchMethod] = useState(false);
    const {slug} = useParams()

    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["EventListCategory" + slug],
        async () => {
            const baseURLCategory = "/wp-json/wc/v3/products/categories?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish";
            const categories = [];
            await apiGet(baseURLCategory).then(r =>
                categories.push(...r.data)
            )
            const baseURL = "/wp-json/wc/v3/products?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish";
            return await apiGet(baseURL).then(re => {
                return re.data.filter(el => {
                    return el.categories.find(f => f.id === categories.find(e => e.slug === decodeURIComponent(slug)).id)
                })
            })
        }
    );

    const loadMore = () => {
        setItemEnd(itemEnd + 6);
    }

    const filterBySearch = () => {
        if (!isLoading) {
            let word = document.getElementById('searchProduct').value;

            if (word.length > 0) {
                setOnSearchMethod(true);
                let filteredItems = [].concat(data).filter((item) => {
                    return item.name.toLowerCase().match(word.toLowerCase());
                })
                setEventSearchItems(filteredItems);
            } else {
                setOnSearchMethod(false);
            }
        }
    }

    return (
        <>
            <Helmet>
                <title>Acceliot - News</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            Internet of Things News
                        </h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/event">Event List</Link></li>
                            <li>Event Category</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">

                    <div className="row">
                        {
                            (!isLoading) ?
                                data.slice(itemStart, itemEnd).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <EventCard elements={item}/>
                                    </div>
                                ) :
                                Array.apply(0, Array(6)).map((item, i) =>
                                    <div key={i} className="col-lg-4 col-md-6 mb30">
                                        <EventCardSkeleton/>
                                    </div>
                                )
                        }

                        {
                            (!isLoading) ?
                                data.length === 0 ?
                                    <>
                                        <div className="text-center">
                                            No Item Found! 😢
                                        </div>
                                        <Link to="/event" className="mt-3 d-block text-center">
                                            <button className="btn btn-secondary">
                                                Back to Events
                                            </button>
                                        </Link>
                                    </>
                                    : '' : null
                        }
                    </div>

                    <div className="spacer-single"/>

                    <ul className="pagination mt-5">
                        {
                            (!isLoading) ?
                                (data.length > 6) ?
                                    <button type="button" className="btn btn-dark mx-auto"
                                            onClick={loadMore}>
                                        Load More
                                    </button> : null : null
                        }
                    </ul>

                </div>
            </section>
        </>
    )
}
