import React, {useContext} from 'react';
import LoginForm from "../../components/Account/LoginForm";
import RegisterForm from "../../components/Account/RegisterForm";
import '../../components/Account/Style.css'

const Login = () => {

    return (
        <section id="loginPage">
            <div className="container" id="toggleAccount">
                <div className="user signinBx">
                    <div className="imgBx"><img src="/assets/img/Site/IoT-Academy-logo-white.png" alt=""/></div>
                    <div className="formBx">
                        <LoginForm/>
                    </div>
                </div>
                <div className="user signupBx">
                    <div className="formBx">
                        <RegisterForm/>
                    </div>
                    <div className="imgBx"><img src="/assets/img/Site/IoTAcademy-logo.png" alt=""/></div>
                </div>
            </div>
        </section>
    );
};

export default Login;