import Skeleton from 'react-loading-skeleton';

const BlogCardSkeleton = () => {

    function hightCalculation() {
        if (window.innerWidth > 991) {
            return (1320 / 3 - 30) * 2 / 3 + 'px'
        } else if (window.innerWidth < 992 && window.innerWidth > 425) {
            return 720 / 2 - 30 + 'px'
        } else {
            return window.innerWidth - 30 + 'px'
        }
    }


    return (
        <div className="latest__item">
            <div className="latest__item__pic">
                <div>
                    <Skeleton height={hightCalculation()}/>
                </div>

            </div>
            <div className="latest__item__text">
                <span className="catItem">
                    <Skeleton width="30%"/>
                </span>
                <h4>
                    <div>
                        <Skeleton count={2}/>
                    </div>
                </h4>
                <div><Skeleton width="40%"/></div>
            </div>
        </div>
    );
};

export default BlogCardSkeleton;