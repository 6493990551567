import {Link} from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay, Keyboard} from "swiper";
import {apiGet} from "../Tools/apiConfig";
import {useQuery} from "react-query";
import ErrorPage from "../../pages/Errors/ErrorPage";

const Slider = () => {
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Slider"],
        async () => {
            let fields = "_fields[]=title&_fields[]=content&_fields[]=better_featured_image&_fields[]=slug&_fields[]=featured_media&_fields[]=button_text&_fields[]=button_link";
            const baseURL = "/wp-json/wp/v2/slider?" + fields;
            return await apiGet(baseURL)
        }
    );

    if (isLoading || !isSuccess) {
        return (
            <section className="home-slides owl-theme">
                {
                    <Skeleton height={"100vh"}/>
                }
            </section>
        )
    }

    if (isError) {
        return <ErrorPage errorMessage={error}/>;
    }

    return (
        <section className="position-relative">
            <Swiper
                modules={[Navigation, Keyboard]}
                loop={true}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false
                }}
                className={'home-slides owl-theme'}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                keyboard={{enabled: true, onlyInViewport: true}}
                // navigation={{
                //     prevEl: navigationPrevRef.current,
                //     nextEl: navigationNextRef.current,
                // }}
            >
                {
                    data.data.map((item, i) =>
                        <SwiperSlide key={i}>
                            <div className="banner-section jarallax"
                                 style={{backgroundImage: "url(" + item.better_featured_image.source_url + ")"}}
                                 data-jarallax='{"speed": 0.2}'>
                                <div className="container">
                                    <div className="hero-content">
                                        <h1>{item.title.rendered}</h1>
                                        <div dangerouslySetInnerHTML={{__html: item.content.rendered}}/>
                                        <div>
                                            <Link to={item.button_link}
                                                  className="slider-btn primary-btn normal-btn text-white">
                                                {item.button_text}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )

                }
            </Swiper>
            {/*<div ref={navigationPrevRef} className="navigationBtn navPrev">*/}
            {/*    <i className="fas fa-chevron-left"/>*/}
            {/*</div>*/}
            {/*<div ref={navigationNextRef} className="navigationBtn navNext">*/}
            {/*    <i className="fas fa-chevron-right"/>*/}
            {/*</div>*/}
        </section>
    )

};


export default Slider;