import {Link} from "react-router-dom";
import React from "react";

const BlogCard = (props) => {
    let item = props.elements
    return (
        <div className="latest__item">
            <div className="latest__item__pic">
                <Link to={"/blog/" + item.slug}>
                    <img
                        src={(item.featured_media != 0) ? item?.better_featured_image?.source_url : item?.better_featured_image?.source_url}
                        alt={item.title.rendered} className="lazy"/>
                </Link>

            </div>
            <div className="latest__item__text">
                <span className="catItem">
                <i className="fas fa-list me-1"/>
                    {item._embedded["wp:term"][0].map((item2, i) =>
                        <Link key={i} to={"/blog/category/" + item2.slug}>
                            <span className="mb-0" dangerouslySetInnerHTML={{__html: item2.name}}/>
                        </Link>
                    )}
                </span>
                <h4>
                    <Link
                        to={"/blog/" + item.slug}>{(item.title.rendered.length > 60) ? item.title.rendered.substring(0, 57) + '...' : item.title.rendered}</Link>
                </h4>
                <Link to={"/blog/" + item.slug}>Read more</Link>
            </div>
        </div>
    );
};
export default BlogCard;