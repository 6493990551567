import {useContext, useEffect, useState} from 'react';
import {Logout} from "../../components/Account/Logout";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";

const AccountLayout = ({children}) => {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext)
    const [nickname, setNickname] = useState('')

    useEffect(() => {
        if (typeof isLogin.status !== "undefined") {
            setNickname(isLogin.user.nickname)
        }

    }, [isLogin])


    return (
        <>
            <Helmet>
                <title>IoT Academy - Account</title>
            </Helmet>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            Account
                        </h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>My Account</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section>
                <div className="container py-5">
                    <div className="row">
                        <div className="col">
                            <nav className="navbar navbar-expand-lg navbar-light bg-white mb-3">
                                <div className="container-fluid">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item  d-flex justify-content-between align-items-center p-3">
                                            <i className="fas fa-warehouse me-1"/>
                                            <span className="nav-link">
                                                    Dashboard</span>
                                        </li>
                                    </ul>
                                    <div className="d-flex align-items-center">
                                        <div className="dropdown">
                                            {/*<a href="" className="mr-5">*/}
                                            {/*    <i className="fas fa-envelope fa-lg"/>*/}
                                            {/*    <span*/}
                                            {/*        className="badge rounded-pill badge-notification bg-danger">*</span>*/}
                                            {/*</a>*/}
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-body text-center">
                                    <img
                                        src={
                                            isLogin.avatar
                                                ? isLogin.user.avatar
                                                : "/assets/img/user.png"
                                        }
                                        alt="avatar" className="rounded-circle img-fluid" style={{width: "150px"}}/>
                                    <h5 className="my-3 text-capitalize">
                                        {
                                            nickname !== '' ?
                                                <>
                                                    {nickname}
                                                </>
                                                : <i className="fa fa-spinner fa-spin"/>
                                        }
                                    </h5>
                                    <div className="d-flex justify-content-center mb-2">
                                        <Link className="btn" to="/myaccount/Editprofile">Edit <i className="fa fa-pen"/></Link>

                                        <button type="button" className="btn btn-outline-danger ms-1"
                                                onClick={Logout}>Logout <i className="fa fa-sign-out-alt"/>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card mb-4 mb-lg-0">
                                <div className="card-body p-0">
                                    <ul className="list-group list-group-flush rounded-3">
                                        <Link to="/myaccount">
                                            <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                                <p className="mb-0">Profile</p>
                                                <i className="far fa-user-circle fa-lg text-warning"/>
                                            </li>
                                        </Link>
                                        <Link to="/myaccount/orders">
                                            <li className="list-group-item d-flex justify-content-between align-items-center p-3">
                                                <p className="mb-0">Orders History</p>
                                                <i className="fa fa-money-bill fa-lg text-warning"/>
                                            </li>
                                        </Link>
                                        {/*<Link to="/myaccount/ticket">*/}
                                        {/*    <li className="list-group-item d-flex justify-content-between align-items-center p-3">*/}
                                        {/*        <p className="mb-0">Ticket</p>*/}
                                        {/*        <i className="far fa-envelope fa-lg" style={{color: "#333333"}}/>*/}
                                        {/*    </li>*/}
                                        {/*</Link>*/}
                                        {/*<li className="list-group-item d-flex justify-content-between align-items-center p-3">*/}
                                        {/*    <p className="mb-0">Order</p>*/}
                                        {/*    <i className="fas fa-file-invoice fa-lg" style={{color: "#55acee"}}/>*/}
                                        {/*</li>*/}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AccountLayout;