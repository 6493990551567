import React from 'react';
import {useContext, useEffect, useState} from "react";
import {UiDataContext} from "../../context/UiDataContext";
import {Helmet} from "react-helmet";
import {useQuery} from "react-query";
import {apiGet} from "../../components/Tools/apiConfig";
import ErrorPage from "../Errors/ErrorPage";

const About = () => {
    const [About, setAbout] = useState();
    const [loaded, setLoaded] = useState(false);
    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["Ui_items"],
        () => {
            let fields = '_fields[]=title&_fields[]=content&_fields[]=better_featured_image&_fields[]=featured_media&_fields[]=slug&_fields[]=head_title&_fields[]=gallery&slug=aboutus'
            const baseURL = "/wp-json/wp/v2/ui_editor?" + fields;
            return apiGet(baseURL)
        }
    );

    useEffect(() => {
        if(isSuccess) {
            setAbout(data.data.filter(el => el.slug === "aboutus")[0])
            setLoaded(true)
        }
    }, [isSuccess]);

    if (isLoading || !isSuccess || !loaded) {
        return <div>lOADING</div>
    }

    if (error) {
        return <ErrorPage errorMessage={error}/>
    }

    return (
        <>
            <Helmet>
                <title>IoT Academy - About Us</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>About Us</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </div>


            <section className="about-area pt-5 pb-0">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 order-lg-1 order-2 mt-5 mt-lg-0">
                            <div className="about-content">
                                <h2>About IoT Academy</h2>
                                {/*<h2>{About.head_title}</h2>*/}
                                {/*<div className="pe-lg-4" style={{textAlign: "justify"}}>*/}
                                {/*    IoT Academy, as an ITU center of excellence (CoE) in the priority area of Internet*/}
                                {/*    of Things, in Asia and the Pacific region, and Academia member of ITU (International*/}
                                {/*    Telecommunication Union), is leading international institute in the field of*/}
                                {/*    Internet of Things and related technologies, and provides international training*/}
                                {/*    courses and events and making consultant services around the IoT and related*/}
                                {/*    technologies, smart cities, industry4.0, industry 5.0, and digital transformation,*/}
                                {/*    and is an IoT ecosystem for realizing the increasing citizen awareness, empowering*/}
                                {/*    HR & organizations, IoT and smart city consulting services, IoT and smart city*/}
                                {/*    laboratory, research and development, and collaboration with business accelerators.*/}
                                {/*    IoT Academy does empower creative and innovative human resources for them through*/}
                                {/*    their skill-based training program. Considering the importance of the Internet of*/}
                                {/*    Things and its rapid prevalence, specialist institutions need to educate and empower*/}
                                {/*    IoT worldwide. By taking advantage of skillful experts in this field, IoT Academy*/}
                                {/*    has become a specialized IoT and Smart City resource to raise citizens' awareness*/}
                                {/*    and empower human resources, organizations, companies, and industries. All citizens*/}
                                {/*    need to be empowered to expand their ability to use IoT and Smart City tools and*/}
                                {/*    services. Also, IoT and Smart City organizations, companies, and industries need*/}
                                {/*    empowered human resources (HRs) for developing new products and services in this*/}
                                {/*    field. In this way, IoT Academy helps all stakeholders in this field.*/}
                                {/*</div>*/}
                                <div className="pe-lg-4" style={{textAlign: "justify"}} dangerouslySetInnerHTML={{__html: About?.content?.rendered}}/>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 order-lg-2 order-1">
                            <div className="about__pic">
                                <div className="about_pic_inner">
                                    <img src={About?.better_featured_image?.source_url} alt={About?.head_title}/>
                                    {/*<img src={About.better_featured_image.source_url} alt={About.head_title}/>*/}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 ptb-100 order-3">
                            <section className="about-page spad">
                                <div className="container">
                                    <div className="row">

                                        <div className="col-lg-12 col-md-12">
                                            <div className="about_page_services">

                                                <div className="about_text aboutpage_text">
                                                    <div className="section-title about-us">

                                                        <h2 className="mb-2">Our Vision & Our Mission</h2>
                                                    </div>

                                                </div>

                                                <div className="row">

                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="single-about-box">
                                                            <div className="icon">
                                                                <i className="flaticon-support"/>
                                                            </div>
                                                            <h3>
                                                                Our Vision
                                                            </h3>
                                                            <div>
                                                                “Internet of Things products and solutions for the
                                                                unique living of world people”
                                                            </div>
                                                            {/*<h3>{Vision.head_title}</h3>*/}
                                                            {/*<div dangerouslySetInnerHTML={{__html: Vision.content.rendered}}/>*/}

                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-6">
                                                        <div className="single-about-box">
                                                            <div className="icon">
                                                                <i className="flaticon-support"/>
                                                            </div>
                                                            <h3>
                                                                Our Mission
                                                            </h3>
                                                            <div>
                                                                “Increasing citizen awareness and empowering human
                                                                resources, organizations, and industries in the field of
                                                                Internet of Things and related technologies”
                                                            </div>
                                                            {/*<h3>{Mission.head_title}</h3>*/}
                                                            {/*<div dangerouslySetInnerHTML={{__html: Mission.content.rendered}}/>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-lg-10 mx-auto mb-5 ">
                            <ul className="timeline">
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2023</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">World IoT Top 500 Companies</h3>
                                        <p>Achieved a Position Among the Top 250 IoT Companies</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2022</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">World IoT Top 500 Companies</h3>
                                        <p>Achieved a Position Among the Top 250 IoT Companies</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2021</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">World IoT Top 500 Companies</h3>
                                        <p>Achieved a Position Among the Top 250 IoT Companies</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2020</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">World IoT Top 500 Companies</h3>
                                        <p>Achieved a Position Among the Top 250 IoT Companies</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2019</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">Deputy of IoT & Big Data Commission</h3>
                                        <p>Deputy of IoT & Big Data Commission in Tehran ICT Guild Organization.</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2019</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">ISO/IEC JTC1 SC41</h3>
                                        <p>
                                            Chairman of the corresponding ISO/IEC JTC1 SC41 (Internet of Things and
                                            Related
                                            Technologies
                                            Standards) in Iran.
                                        </p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2019</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">ITU Center of Excellence</h3>
                                        <p>
                                            ITU Center of Excellence (CoE) in the Priority area of Internet of
                                            Things
                                            for Asia and
                                            the
                                            Pacific
                                        </p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2019</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">World IoT Top 500 Companies</h3>
                                        <p>Achieved a Position Among the Top 250 IoT Companies</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2018</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">Academia Member</h3>
                                        <p>Join ITU as a ITU Academia Member</p>
                                    </div>
                                </li>
                                <li className="timeline-item">
                                    <div className="timeline-info">
                                        <span>2014</span>
                                    </div>
                                    <div className="timeline-marker"></div>
                                    <div className="timeline-content">
                                        <h3 className="timeline-title">Founded</h3>
                                        <p>Founded and Headquarter Currently Located in Tehran, Iran </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default About;