import {Helmet} from "react-helmet";
import {apiGet, apiPost} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";
import CryptoJS from "crypto-js";
import AccountLayout from "../../components/Account/AccountLayout";
import {useHistory} from "react-router-dom";

const NewTicket = () => {
    const history = useHistory();

    const replyTicket = async (e) => {
        e.preventDefault();
        const baseURL = "/wp-json/wpas-api/v1/tickets";

        let bytes = CryptoJS.AES.decrypt(window.localStorage.getItem('auth'), 'iotaciKey');
        let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        let option = {
            title: document.getElementsByName('title')[0].value,
            content: document.getElementsByName('ticketReply')[0].value,
        }
        try {
            const fetchData = await apiPost(baseURL, option, {
                auth: {
                    username: decryptedData.username,
                    password: decryptedData.password
                },
            }).then(async (response) => {
                e.target.reset()
                toast.success("Your Message Sent!");
                history.push(`/myaccount/ticket`);
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <AccountLayout>
            <Helmet>
                <title>IoT Academy - Account</title>
            </Helmet>

            <form onSubmit={replyTicket} className="bg-white">
                <input name="title" id="title" className="form-control p-4" placeholder="Your Message Title"/>
                <textarea name="ticketReply" placeholder="Your Message Description"
                          className="form-control w-100 h-auto p-4"
                          rows="4"/>
                <div className="text-center">
                    <button className="btn btn-dark my-4">Send <em className="fa fa-paper-plane"/></button>
                </div>
            </form>

        </AccountLayout>
    )
        ;
};

export default NewTicket;