import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";

const NotFound = () => {
    return (
        <>
            <Helmet style={[{
                "cssText": `
            header {
                display: none;
            }
            footer {
            display: none;
            }
             `
            }]}>
                <title>IoT Academy - 404</title>
            </Helmet>
            <section className="error-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="error-content">
                                <img src="/assets/img/Site/IoTAcademy-logo.png" alt="error"/>
                                <h3>Page Not Found</h3>
                                <p>
                                    The page you are looking for might have been removed had its name changed or is
                                    temporarily
                                    unavailable.
                                </p>
                                <Link to="/" className="btn btn-secondary">Go to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
        ;
};

export default NotFound;