import {useRef} from 'react';
import EventCard from "./EventCard";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Keyboard} from "swiper";

import EventCardSkeleton from "./EventCardSkeleton";

const RelatedEvent = ({items}) => {
    const navigationPrevRef = useRef(null)
    const navigationNextRef = useRef(null)
    return (
        <div className="eventCarousle position-relative">

            <Swiper
                modules={[Navigation, Keyboard]}
                className={'swiper-container slider-zoom-slide slider-zoom-slide-dark black-move p-0'}
                spaceBetween={30}
                slidesPerView={1}
                loop={true}
                breakpoints={{
                    992: {
                        slidesPerView: 3
                    },
                    767: {
                        slidesPerView: 3,
                    }
                }}
                keyboard={{enabled: true, onlyInViewport: true}}
                navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                }}
            >
                {

                    (items.length > 0) ?
                        items.slice(0, 6).map((item, i) =>
                            <SwiperSlide className="mb30" key={i}>
                                <EventCard elements={item}/>
                            </SwiperSlide>
                        ) :
                        Array.apply(0, Array(6)).map((item, i) =>
                            <SwiperSlide className="mb30" key={i}>
                                <EventCardSkeleton/>
                            </SwiperSlide>
                        )
                }
            </Swiper>
            <div ref={navigationPrevRef} className="navigationBtn navPrev">
                <i className="fas fa-chevron-left"/>
            </div>
            <div ref={navigationNextRef} className="navigationBtn navNext">
                <i className="fas fa-chevron-right"/>
            </div>

        </div>


    );
};

export default RelatedEvent;