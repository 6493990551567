import {createContext, useState} from 'react';
export const TicketsDataContext = createContext(undefined);

export const TicketsDataProvider = (props) => {
    const [ticketItems, setTicketsItems] = useState([])
    return (
        <TicketsDataContext.Provider value={{ticketItems, setTicketsItems}}>
            {props.children}
        </TicketsDataContext.Provider>
    );
};