import Routing from "./routes/Routing";
import {useContext, useEffect} from "react";
import { apiPost} from "./components/Tools/apiConfig";
import {AuthorizeDataContext} from "./context/AuthorizeDataContext";
import {UserMeta} from "./components/Tools/UserMeta";
import {QueryClient, QueryClientProvider} from "react-query";


function App() {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext);

    const addDataIntoCache = (cacheName, url, response) => {
        const data = new Response(JSON.stringify(response));
        if ('caches' in window) {
            // Opening given cache and putting our data into it
            caches.open(cacheName).then((cache) => {
                cache.put(url, data);
                localStorage.setItem('cached', Date.now())
            });
        }
    };

    const getSingleCacheData = async (cacheName, url) => {
        if (typeof caches === 'undefined') return false;

        const cacheStorage = await caches.open(cacheName);
        const cachedResponse = await cacheStorage.match(url);

        // If no cache exists
        if (!cachedResponse || !cachedResponse.ok) {
            return false
        }
        return cachedResponse.json().then((item) => {
            return item
        })
    };

    const checkCacheExpire = () => {
        let nowTime = new Date(Date.now());
        let cachedTime = new Date(Number(localStorage.getItem('cached')))
        return (Math.abs(nowTime - cachedTime) > 300000);
    }

    const getUserData = async () => {
        if (window.localStorage.getItem('gd-auth') !== null) {
            let localData = window.localStorage.getItem('gd-auth')

            const baseURL = "/api/user/get_currentuserinfo/?cookie=" + JSON.parse(localData).cookie;

            try {
                const fetchData = await apiPost(baseURL).then((response) => {
                    setIsLogin(response.data);
                });
                await UserMeta()
            } catch (err) {
                console.error(err);
            }
        }
    };


    useEffect(() => {
        getUserData();
    }, [])

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            }
        }
    })

    return (
        <QueryClientProvider client={queryClient}>
            <Routing/>
        </QueryClientProvider>
    );
}

export default App;
