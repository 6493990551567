import React from 'react';
import {Link, useHistory, useLocation} from "react-router-dom";
import {apiPut} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";

const ChangePassword = (props) => {

    function useQuery() {
        const {search} = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    const history = useHistory();
    let query = useQuery();

    const resetPassword = (e) => {
        e.preventDefault()
        let userRestPassData = {
            email: query.get("user"),
            code: query.get("code"),
            new_password: document.getElementsByName('password')[0].value,
            AUTH_KEY: 'AUTH_KEY',
            // privacy: document.getElementById('check1').checked
        }
        if (userRestPassData.new_password === document.getElementsByName('passwordConfirmation')[0].value) {
            if (userRestPassData.email != '' && userRestPassData.code != '' && userRestPassData.new_password != '') {
                let baseURL = `/?rest_route=/simple-jwt-login/v1/user/reset_password`;
                try {
                    const fetchData = apiPut(baseURL, userRestPassData).then((response) => {
                        console.log(response.data)
                        if (response.data.success) {
                            toast.success("Your Password Successfully Changed!");
                        } else {
                            toast.error("Check Your Inputs!");
                        }
                        history.push(`/login`);
                    });
                } catch (err) {
                    console.error(err);
                }
                document.getElementById('resetPassword').reset()
            } else {
                toast.error("Check Your Inputs!");
            }
        } else {
            toast.error("Your Confirmation Password is not correct!");
        }


    }

    return (
        <>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            IoT Academy Reset Password
                        </h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Reset Password</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="relative vertical-center ptb-100">
                <div className="overlay-gradient t50">
                    <div className="center-y relative">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-4 mx-auto wow fadeIn" data-wow-delay=".5s">
                                    <div className="box-rounded padding40" data-bgcolor="#ffffff">
                                        <h3 className="mb10">Recovery Password</h3>
                                        <p/>
                                        <form name="resetPassword" id='resetPassword'
                                              className="form-border"
                                              onSubmit={resetPassword}>

                                            <div className="field-set">
                                                <input type='password' name='password' id='password'
                                                       className="form-control"
                                                       placeholder="New Password"/>
                                            </div>
                                            <p/>
                                            <div className="field-set">
                                                <input type='password' name='passwordConfirmation'
                                                       id='passwordConfirmation'
                                                       className="form-control"
                                                       placeholder="New Password Confirmation"/>
                                            </div>
                                            <p/>
                                            <div className="field-set">
                                                <input type='submit' id='send_message' value='Change Password'
                                                       className="btn btn-secondary "
                                                />
                                            </div>

                                            <div className="clearfix"/>

                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default ChangePassword;