import Skeleton from 'react-loading-skeleton';

const ServicesHomeCard = ({elements}) => {
    return (
        <div className="col-lg-4 col-md-6 col-12 mb-3">
            <div className="services-box">
                <Skeleton circle={true} height={75} width={75} />
                <h3>
                    <Skeleton width="40%" />
                </h3>
                <p><Skeleton count={5}/></p>
                <a href="#" className="details-btn"><i className="flaticon-arrow-pointing-to-right"/></a>
            </div>
        </div>
    );
};

export default ServicesHomeCard;