import React from 'react';
import Header from "./header";
import Footer from "./footer";
import {useLocation} from "react-router-dom";

const Layout = ({children}) => {
    let path = useLocation().pathname
    // window.localStorage.setItem('lang', 'en')
    return (
        <>
            {/*<div className="preloader">*/}
            {/*    <div className="spinner-border"/>*/}
            {/*</div>*/}
            {
                (path !== '/login') ?
                    <Header/> : ''
            }
            <div className="no-bottom no-top" id="content">
                <div id="top"/>
                {children}
            </div>

            {
                (path !== '/login') ?
                    <Footer/>
                    : ''

            }
            <a href="#" id="back-to-top" className="show"/>
        </>
    );
};

export default Layout;