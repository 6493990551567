import React, {useState} from 'react';
import {Helmet} from "react-helmet";
import {apiPost} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";

const ComingSoon = () => {
    const [abs, setAbs] = useState(0)
    const NewsLetterSubmit = (e) => {
        e.preventDefault();
        let mailId = document.getElementsByName('emailId')[0].value;
        if (mailId !== "") {
            let formdata = new FormData();
            formdata.append("your-email", mailId);

            const baseURL = "/wp-json/contact-form-7/v1/contact-forms/307/feedback"
            apiPost(baseURL, formdata)
                .then(response => {
                    if (response.data.status === "mail_sent") {
                        setAbs(1);
                        toast.success("You registered successfully.")
                        document.getElementById('form_subscribe').reset();
                    } else {
                        toast.error("Error, Please check your email.")
                    }

                }).catch(() => {
                toast.error("Error, Check your connection")
            })

        }
    }
    const submitSubscribe = (e) => {
        e.preventDefault();
        NewsLetterSubmit(e);
    }
    return (
        <>
            <Helmet style={[{
                "cssText": `
            header {
                display: none;
            }
            footer {
            display: none;
            }
             `
            }]}>
                <title>IoT Akademie - Demnächst</title>
            </Helmet>
            <section className="error-area coming-soon">
                <div className="d-flex justify-content-between align-items-end flex-wrap">
                    <div className="container pt-5">
                        <div className="soon-content">
                            <img src="/assets/img/Site/IoTAcademy-logo.png" alt="error" width="180px" className="mb-5"/>
                            <h1 className={"my-4"}>Demnächst</h1>
                            <h5 className={"mb-4"}>Soon we will have this site in German.</h5>
                            <div className="soon-social pb-5">
                                <a href="https://www.linkedin.com/company/iotacademy" target="_blank"
                                   rel="noreferrer">
                                    <i className="fab fa-linkedin mx-2"/>
                                </a>
                                <a href="https://www.instagram.com/IotAcademy" target="_blank" rel="noreferrer">
                                    <i className="fab fa-instagram mx-2"/>
                                </a>
                            </div>
                            <div className="pt-5 newsletter">
                                <p className="text-center text-black-50 newsletter-title">Subscribe to our mailing list to get latest
                                    updates</p>
                                <form id="form_subscribe" onSubmit={NewsLetterSubmit}
                                      className="soon-news-letter w-100 d-flex justify-content-center">
                                    <input type="text" placeholder="Email" id="txt_subscribe"
                                           data-minemail-com="0" name="emailId"/>

                                    <button onClick={submitSubscribe} type="button">Subscribe</button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="container pb-2 mt-5 mt-lg-0">
                        <p className="text-center text-black-50">©Copyrights IoTAcademy | All Rights Reserved</p>
                    </div>
                </div>
            </section>
        </>

    );
};

export default ComingSoon;
