import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import {apiGet, apiPost} from "../../components/Tools/apiConfig";
import RelatedEvent from "../../components/Event/RelatedEvent";
import {useQuery} from "react-query";
import axios from "axios";
import {toast} from "react-toastify";


const EventDetail = () => {
    const {slug} = useParams();
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IRR',
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["EventList" + slug],
        async () => {
            const baseURL = `/wp-json/wc/v3/products?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish&slug=${slug}`;
            return apiGet(baseURL)
        }
        //     const baseURL = "/wp-json/wc/v3/products?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish";
        //     return await apiGet(baseURL).then(r => {
        //         let item = r.data.find(element => decodeURIComponent(element.slug) === slug);
        //         if (item) {
        //             console.log(item)
        //             setElements(item);
        //             setId(item.id)
        //             setDownloads(item.downloads);
        //             setRegistrationDateStart(item.meta_data.find(elements => elements.key === 'registration_date_start').value)
        //             setRegistrationDateEnd(item.meta_data.find(elements => elements.key === 'registration_date_end').value)
        //             setEventStart(item.meta_data.find(elements => elements.key === 'event_date_start').value)
        //             setEventEnd(item.meta_data.find(elements => elements.key === 'event_date_end').value)
        //             setLocation(item.meta_data.find(elements => elements.key === 'location').value)
        //             setTrainingType(item.meta_data.find(elements => elements.key === 'training_type').value)
        //             setEventLanguage(item.meta_data.find(elements => elements.key === 'event_language').value)
        //             setTutors(item.meta_data.find(elements => elements.key === 'tutors').value)
        //             setEventOrganizer(item.meta_data.find(elements => elements.key === 'event_organizer').value)
        //             setImage(item.images[0].length != 0 ? item.images[0].src : '');
        //             // setCategories(item._embedded["wp:term"][0]);
        //             // setTags(item._embedded["wp:term"][1]);
        //             setEnLink(item.meta_data.find(elements => elements.key === 'enroll_link_english').value);
        //             setFaLink(item.meta_data.find(elements => elements.key === 'enroll_link_persian').value);
        //             let relatedItems = [];
        //             relatedItems.push(r.data.filter(em => {
        //                 return item.related_ids.filter(el => {
        //                     if (em.id === el) {
        //                         return em
        //                     }
        //                 })
        //
        //             }))
        //             setRelatedItems(relatedItems.length > 0 ? relatedItems[0] : relatedItems);
        //         }
        //         return r
        //     })
        // }
    );
    return (
        <>
            <Helmet>
                <title>IoT Academy - Events</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h1>{!isLoading && data.data[0].name}</h1>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/event">Event List</Link></li>
                            <li>Event Detail</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="product-details-area ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="product-details-image">
                                {
                                    !isLoading ?
                                        <img src={data.data[0].images[0].length != 0 ? data.data[0].images[0].src : ''}
                                             className="lazy"
                                             alt={data.data[0].name}
                                        /> :
                                        <Skeleton width={400} height={400}/>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="product-details-desc">
                                {
                                    !isLoading ?
                                        <h3>{data.data[0].name}</h3> :
                                        <Skeleton width={'80%'}/>
                                }

                                <div className="row">
                                    {
                                        !isLoading ?
                                            <>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-clock p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Registration</h2>
                                                                    <h6 className="card-liner-subtitle">
                                                                        {data.data[0].meta_data.find(el => el.key === 'registration_date_start').value} - {data.data[0].meta_data.find(el => el.key === "registration_date_end").value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-clock p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Event Dates</h2>
                                                                    <h6 className="card-liner-subtitle">
                                                                        {data.data[0].meta_data.find(el => el.key === 'event_date_start').value} - {data.data[0].meta_data.find(el => el.key === "event_date_end").value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-map p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Location</h2>
                                                                    <h6 className="card-liner-subtitle">
                                                                        {data.data[0].meta_data.find(el => el.key === 'location').value}
                                                                    </h6>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-user p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Tutors</h2>
                                                                    <h6 className="card-liner-subtitle">
                                                                        {data.data[0].meta_data.find(el => el.key === 'tutors').value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-file p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Training Type</h2>
                                                                    <h6 className="card-liner-subtitle">{data.data[0].meta_data.find(el => el.key === 'training_type').value}</h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-language p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Language</h2>
                                                                    <h6 className="card-liner-subtitle">
                                                                        {data.data[0].meta_data.find(el => el.key === "event_language").value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 mb-3">
                                                    <div>
                                                        <div>
                                                            <div className="d-flex px-0 px-lg-2 py-2 align-self-center">
                                                                <i className="mt-2 fa fa-home p-icon"/>
                                                                <div className="card-liner-content">
                                                                    <h2 className="card-liner-title">Event
                                                                        Organizer</h2>
                                                                    <h6 className="card-liner-subtitle">
                                                                        {data.data[0].meta_data.find(el => el.key === "event_organizer").value}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </> :
                                            Array.apply(0, Array(7)).map((item, i) => {
                                                return (
                                                    <div className="col-lg-6 mb-3" key={i}>
                                                        <Skeleton width={'40%'}/>
                                                        <Skeleton width={'80%'}/>
                                                    </div>
                                                )
                                            })
                                    }


                                </div>
                                {/*<div className="col-md-4 col-sm-4 col-xs-4 col-lg-4 d-none">*/}
                                {/*    <div className="row">*/}
                                {/*        <div className="part-details-event-title">*/}
                                {/*            <div className="item-details-event">*/}
                                {/*                <p className="eventdetails">Event Contact</p>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        <div className="box-dt-event">*/}
                                {/*            <div className="part-details-event">*/}

                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="price text-center h4">

                                    {
                                        !isLoading ?
                                            <>
                                                Price:
                                                <span className="new-price text-success">
                                                    <b>{"$" + data.data[0].meta_data.find(el => el.key === "dollor_price").value}</b> <span
                                                    className="small">({formatter.format(data.data[0].price)})</span>
                                                </span>
                                            </>
                                            :
                                            <Skeleton width={'50%'}/>
                                    }

                                </div>
                                {
                                    (!isLoading) ?
                                        (data.data[0].stock_status === "instock") ?
                                            <div className="buy-checkbox-btn">
                                                <div className="item">
                                                    <a className="buy-btn" data-bs-toggle="modal"
                                                       data-bs-target="#EnrollModal" role="button">Enroll</a>
                                                </div>
                                            </div> :
                                            <div className="buy-checkbox-btn">
                                                <div className="item">
                                        <span
                                            className="buy-btn disabled">The registration to the course is closed.</span>
                                                </div>
                                            </div> :

                                        <Skeleton width={'100%'}/>
                                }


                            </div>
                        </div>


                        <div className="col-lg-12 col-md-12">
                            <div className="tab products-details-tab">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <ul className="nav nav-tabs tabs" id="myTab" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link active" id="description-tab"
                                                   data-bs-toggle="tab"
                                                   data-bs-target="#description"
                                                   type="button" role="tab" aria-controls="home"
                                                   aria-selected="true">
                                                    <div className="dot"/>
                                                    Description
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link" id="documents-tab" data-bs-toggle="tab"
                                                   data-bs-target="#documents"
                                                   type="button" role="tab" aria-controls="profile"
                                                   aria-selected="false">
                                                    <div className="dot"/>
                                                    Documentation
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="tab_content" id="myTabContent">
                                            <div className="tab-pane tabs_item fade show active" id="description"
                                                 role="tabpanel"
                                                 aria-labelledby="description-tab">
                                                {
                                                    !isLoading ?
                                                        <div className="products-details-tab-content"
                                                             dangerouslySetInnerHTML={{__html: data.data[0].description}}/> :
                                                        <Skeleton count={10}/>
                                                }

                                            </div>
                                            <div className="tab-pane tabs_item fade" id="documents" role="tabpanel"
                                                 aria-labelledby="documents-tab">
                                                <h3> Related documentation and links </h3>
                                                {
                                                    !isLoading &&
                                                    data.data[0].downloads.map((item, index) => {
                                                        return (
                                                            <div className="download-box" key={index}>
                                                                <label>{item.name}</label>
                                                                <span/>
                                                                <a className="btn"
                                                                   href={item.file}
                                                                   download=""
                                                                   target="_blank"
                                                                   rel="noreferrer"
                                                                >
                                                                    <div className="download_link">
                                                                        <i className="fa fa-download"/>
                                                                        <p>
                                                                            Download
                                                                        </p>
                                                                    </div>
                                                                </a>
                                                            </div>

                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<div className="related-products-area">*/}
                {/*    <div className="container">*/}
                {/*        <div className="section-title">*/}
                {/*            <h3>Related Event</h3>*/}
                {/*        </div>*/}
                {/*        <div className="row">*/}

                {/*            <div className="eventCarousle owl-carousel">*/}
                {/*                <div className="logo__carousel__item">*/}
                {/*                    <div className="single-blog-post">*/}
                {/*                        <a href="/En/Events/Building IoT Solutions for Smart Agriculture"*/}
                {/*                           className="post-image">*/}
                {/*                            <img*/}
                {/*                                data-src="https://photo.iotaci.com/Event/22-33-6-28-2021 4;01;53 PM-0X0 .jpg"*/}
                {/*                                alt="blog-image" className="lazy"/>*/}
                {/*                        </a>*/}
                {/*                        <div className="blog-post-content">*/}
                {/*                            <ul>*/}
                {/*                                <li><i className="fas fa-list"/> <a href="#">Online</a></li>*/}
                {/*                                <li>*/}
                {/*                                    <i className="far fa-clock"/> Start Date: August*/}
                {/*                                    23, 2021*/}
                {/*                                </li>*/}
                {/*                            </ul>*/}
                {/*                            <h3><a href="/En/Events/Building IoT Solutions for Smart Agriculture">Building*/}
                {/*                                IoT Solutions for Smart Agriculture</a></h3>*/}
                {/*                            <a href="/En/Events/Building IoT Solutions for Smart Agriculture"*/}
                {/*                               className="read-more-btn">*/}
                {/*                                Read More <i className="fas fa-angle-double-right"/>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                    </div>*/}
                {/*                </div>*/}
                {/*            </div>*/}

                {/*            <RelatedEvent items={relatedItems}/>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </section>
            <div className="modal fade" id="EnrollModal" tabIndex="-1" aria-labelledby="©ModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content product-details-desc">
                        <div className="modal-header">
                            <h6 className="modal-title"
                                id="EnrollModalLongTitle">Enroll {!isLoading ? data.data[0].name : ""}</h6>
                        </div>
                        <div className="modal-body">
                            <div className="buy-checkbox-btn row">
                                <div className="item">
                                    <div className="item">

                                        {
                                            !isLoading &&
                                            (data.data[0].meta_data.find(el => el.key === "enroll_link_english")) ?
                                                <a href={data.data[0].meta_data.find(el => el.key === "enroll_link_english").value}
                                                   className="buy-btn">Enroll In Dollar</a>
                                                : ''
                                        }

                                    </div>
                                    <div className="item">
                                        {/*{*/}
                                        {/*    (faLink) ?*/}
                                        {/*        <a href={faLink} className="buy-btn">Enroll In Rials</a> :*/}
                                        {/*        <a href="https://evand.com/events/iotssc" className="buy-btn">Enroll*/}
                                        {/*            In*/}
                                        {/*            Rials</a>*/}
                                        {/*}*/}
                                        {/*<a href={faLink} className="buy-btn">Enroll In Rials</a>*/}
                                        {
                                            !isLoading &&
                                            <Buy product_id={data.data[0].id}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EventDetail;

function Buy({product_id}) {
    const [isLoading, setLoading]= useState(false)
    const user = JSON.parse(window.localStorage.getItem('gd-auth'))
    const preRenderBuy = () => {
        if (user) {
            setLoading(true)
            const user_id = user.user.id
            apiPost('/wp-json/wc/v3/orders?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386', {
                "currency": "IRR",
                "customer_id": user_id,
                "line_items": [
                    {
                        "product_id": product_id
                    }
                ]
            }).then(async o => {
                if (o.status === 201) {
                    console.log(o)
                    await axios.post('https://gateway.zibal.ir/v1/request', {
                        "merchant": "63d2122418f9343f94179b7d",
                        // "merchant": "zibal",
                        "amount": o.data.total,
                        // "callbackUrl": "http://localhost:3000/order_fall_back",
                        "callbackUrl": "https://iotaci.com/order_fall_back",
                        "orderId": o.data.id,
                    }).then(r => {
                        if (r.status === 200) {
                            const trackId = r.data.trackId
                            window.location.href = 'https://gateway.zibal.ir/start/' + trackId
                        }
                    })
                }
            })
        } else {
            toast.error('Login First and then retry again!')
        }
    }

    if (isLoading) {
        return (
            <div className="buy-btn" role={"button"}>
                Loading...
            </div>
        )
    }

    else {
        return (
            <div className="buy-btn" onClick={preRenderBuy} role={"button"}>
                Enroll (zibal)
            </div>
        )
    }

}