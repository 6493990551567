import React, {useState} from 'react';
import EventCard from "../../components/Event/EventCard";
import {Helmet} from "react-helmet";
import EventCardSkeleton from "../../components/Event/EventCardSkeleton";
import {apiGet} from "../../components/Tools/apiConfig";
import EventCategory from "../../components/Event/EventCategory";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";

export default function EventList() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [itemEnd, setItemEnd] = useState(6);
    const [itemStart, setItemStart] = useState(0);
    const [productSearchItems, setEventSearchItems] = useState([]);
    const [onSearchMethod, setOnSearchMethod] = useState(false);

    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["EventList"],
        async () => {
            const baseURL = "/wp-json/wc/v3/products?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386&status=publish&per_page=100";
            return await apiGet(baseURL)
        }
    );

    const loadMore = () => {
        setItemEnd(itemEnd + 6);
    }

    const filterBySearch = () => {
        if (!isLoading) {
            let word = document.getElementById('searchProduct').value;
            if (word.length > 0) {
                setOnSearchMethod(true);
                let filteredItems = [].concat(data.data).filter((item) => {
                    return item.name.toLowerCase().match(word.toLowerCase());
                })
                setEventSearchItems(filteredItems);
            } else {
                setOnSearchMethod(false);
            }
        }
    }
    console.log(data?.data?.length)
    return (
        <>
            <Helmet>
                <title>IoT Academy - Events</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            IoT Academy Events
                        </h2>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li>Event</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="blog-area ptb-100">
                <div className="container">
                    <div className="row top-event mb-3 mb-3">

                        <div className="col-md-6">
                            <aside className="widget-area ev" id="secondary">
                                <section className="widget widget_search">
                                    <form className="search-form" onSubmit={(e) => e.preventDefault()}>
                                        <label>
                                            <span className="screen-reader-text">Search for:</span>
                                            <input type="text" name="searchBlog" id="searchProduct"
                                                   onChange={filterBySearch}
                                                   className="search-field ev"
                                                   placeholder="Search..."/>
                                        </label>
                                        <button id="searchItemEvent" type="button"><i className="fas fa-search"/>
                                        </button>
                                    </form>
                                </section>
                            </aside>
                        </div>
                        <div className="col-md-6">
                            <section className="widget widget_categories ev">
                                <h5 className="widget-title ev mb-2 mb-lg-0">Categories: </h5>
                                <ul className="event-category horizontali">
                                    <EventCategory/>
                                </ul>
                            </section>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div id="myDivBlog">
                                <div className="position-Loader">
                                    <img className="logo-ajaxloader" src="/assets/img/IoTAcademy logo.png"/>
                                    <br/>
                                    <img id="loadingImg" src="/assets/img/loaderajax.gif"/>
                                </div>
                            </div>
                            <div id="eventFilter">
                                <div className="row">

                                    {
                                        (!onSearchMethod) ?
                                            (!isLoading) ?
                                                data.data.slice(itemStart, itemEnd).map((item, i) =>
                                                    <div key={i} className="col-lg-4 col-12 mb-3">
                                                        <EventCard elements={item}/>
                                                    </div>
                                                ) :
                                                Array.apply(0, Array(6)).map((item, i) =>
                                                    <div key={i} className="col-lg-4 col-12 mb-3">
                                                        <EventCardSkeleton/>
                                                    </div>
                                                )
                                            : productSearchItems.slice(itemStart, itemEnd).map((item, i) =>
                                                <div key={i} className="col-lg-4 col-12 mb-3">
                                                    <EventCard elements={item}/>
                                                </div>
                                            )
                                    }
                                </div>
                            </div>
                            <div className="spacer-single"/>

                            <ul className="pagination mt-5">

                                {
                                    (!isLoading && data.data.length > 6 && itemEnd < data.data.length) ?
                                        <button type="button" className="btn btn-dark mx-auto"
                                                onClick={loadMore}>
                                            Load More
                                        </button> : null
                                }


                            </ul>
                        </div>
                    </div>

                </div>
            </section>

        </>
    )
}
