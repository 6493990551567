import React, {useContext, useEffect, useState} from 'react';
import {UiDataContext} from "../../context/UiDataContext";
import Skeleton from "react-loading-skeleton";
import {Helmet} from "react-helmet";
const Terms = () => {
    const {uiItems, setUiItems} = useContext(UiDataContext);
    const [terms, setTerms] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (uiItems.length > 0) {
            setTerms(uiItems.filter(el => el.slug === "terms")[0])
            setIsLoaded(true)
        }
    }, [uiItems]);

    return (
        <>
            <Helmet>
                <title>IoT Academy - Terms</title>
            </Helmet>
            <div className="page-title-area">
                <div className="container">
                    <div className="page-title-content">
                        <h2>Terms and Conditions</h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>Terms</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="faq-area ptb-100">
                <div className="container">
                    <div className="row">
                        {
                            isLoaded ?
                                <article dangerouslySetInnerHTML={{__html: terms.content.rendered}}/> :
                                <>
                                    <Skeleton width={'40%'}/>
                                    <Skeleton count={5}/>
                                    <Skeleton width={'40%'}/>
                                    <Skeleton count={5}/>
                                </>
                        }
                    </div>
                </div>
            </section>
        </>
    );
};

export default Terms;