import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {AuthorizeDataProvider} from "./context/AuthorizeDataContext";
import {ToastContainer} from "react-toastify";
import {DocumentDataProvider} from "./context/DucumentDataContext"
import {UiDataProvider} from "./context/UiDataContext";
import {TicketsDataProvider} from "./context/TicketDataContext";
import {PartnersDataProvider} from "./context/PartnersDataContext";
import 'react-loading-skeleton/dist/skeleton.css';

ReactDOM.render(
    <AuthorizeDataProvider>
        <UiDataProvider>
            <TicketsDataProvider>
                <PartnersDataProvider>
                    <DocumentDataProvider>
                        <App/>
                        <ToastContainer position="top-center"
                                        autoClose={5000}
                                        hideProgressBar={false}
                                        newestOnTop={false}
                                        closeOnClick
                                        pauseOnFocusLoss
                                        draggable
                                        pauseOnHover/>
                    </DocumentDataProvider>
                </PartnersDataProvider>
            </TicketsDataProvider>
        </UiDataProvider>
    </AuthorizeDataProvider>,
    document.getElementById('root')
)
;

