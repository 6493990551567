import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import {apiPost} from "../../components/Tools/apiConfig";

const OrderFallBack = () => {
    const [status, setStaus] = useState('10')
    const [success, setSuccess] = useState('10')
    const [orderId, setOrderId] = useState('0')
    const [loaded, setLoaded] = useState(false)

    const getParams = (x) => {
        let params = (new URL(document.location)).searchParams;
        return params.get(x); // is the string "Jonathan Smith".
    }
    useEffect(() => {
        setStaus(getParams('status'))
        setSuccess(getParams('success'))
        setOrderId(getParams('orderId'))
        if (!loaded) {
            apiPost(`/wp-json/wc/v3/orders/${getParams('orderId')}?consumer_key=ck_1f4c4704a9d0dd65764d8ed1d277573cd7b9807e&consumer_secret=cs_45c23fdfe5c904020cffc700b3e360d2b12ea386`, {
                "status": getParams('success') === '1' ? "completed" : "failed"
            }).then( r => {
               if (r.status === 200) {
                   setLoaded(true)
               }
            })
        }
    }, [])
    return (
        <>
            <Helmet>
                <title>IoT Academy - Order Fallback</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h2>
                            Internet of Things News
                        </h2>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li>Order</li>
                        </ul>
                    </div>
                </div>
            </div>
            <section className="section-word-list pt-5" aria-label="section">
                <div className="container mt50">
                    <div className="row mb-5">
                        <div className="alert alert-info">
                            {loaded ?
                            `Your payment status is: ${success === "1" ? 'Successfully' : 'Unsuccessfully'}` :
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default OrderFallBack;