const ServicesCard = ({elements}) => {

    return (
        <div className="col-12 mb-3">
            <div className="services-box">
                <div className="icon">
                    <i className={elements.icon_class}/>
                </div>
                <h3 dangerouslySetInnerHTML={{__html: elements.title.rendered}}/>
                <div dangerouslySetInnerHTML={{__html: elements.content.rendered}}/>
                <div className="image-box">

                </div>
            </div>
        </div>
    );
};

export default ServicesCard;