import {createContext, useState} from 'react';
export const UiDataContext = createContext(undefined);

export const UiDataProvider = (props) => {
    const [uiItems, setUiItems] = useState([])
    return (
        <UiDataContext.Provider value={{uiItems, setUiItems}}>
            {props.children}
        </UiDataContext.Provider>
    );
};