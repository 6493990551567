import {useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import BlogCategory from '../../components/Blog/BlogCategory';
import BlogTags from '../../components/Blog/BlogTags';
import 'react-loading-skeleton/dist/skeleton.css';
import {apiGet} from "../../components/Tools/apiConfig";
import {useQuery} from "react-query";

const BlogDetail = () => {
    const [id, setId] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState('');
    const [date, setDate] = useState('');
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]);
    const {slug} = useParams()

    const {isLoading, isError, data, error, refetch, isSuccess} = useQuery(
        ["BlogList" + slug],
        async () => {
            const baseURL = "?rest_route=/wp/v2/posts&_embed&per_page=100";
            return await apiGet(baseURL).then(r => {
                let item = r.data.find(element => decodeURIComponent(element.slug) === slug);
                if (item) {
                    setId(item.id);
                    setTitle(item.title.rendered);
                    setContent(item.content.rendered);
                    setDate(item.date)
                    setImage(item.better_featured_image !== null ? item.better_featured_image.source_url : '');
                    setCategories(item._embedded["wp:term"][0]);
                    setTags(item._embedded["wp:term"][1]);
                }
            })
        }
    );

    return (
        <>
            <Helmet>
                <title>IoT Academy - BlogDetails</title>
            </Helmet>
            <div className="page-title-area page-title-style-two">
                <div className="container">
                    <div className="page-title-content">
                        <h1 className="h2"/>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/blog">Blog</a></li>
                            <li>Blog Details</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section className="blog-details-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details">
                                <div className="article-image">
                                    <img src={image} alt="" className="lazy"/>
                                </div>
                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li><i className="far fa-calendar-alt"/>
                                                {
                                                    (!isLoading) ?
                                                        new Date(date).toLocaleDateString('En-Us', {
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric'
                                                        }) :
                                                        <Skeleton
                                                            width={'100px'}/>
                                                }
                                            </li>
                                            <li>
                                                <i className="fas fa-list"/>
                                                {categories.map((item2, i) =>
                                                    <Link key={i} to={"/blog/category/" + item2.slug}>
                                                        <span dangerouslySetInnerHTML={{__html: item2.name}}/>
                                                    </Link>
                                                )}
                                            </li>
                                        </ul>
                                    </div>
                                    <h3> {title}</h3>
                                    {
                                        (!isLoading) ?
                                            <div className="blog-text" dangerouslySetInnerHTML={{__html: content}}/> :
                                            <Skeleton count={10}/>
                                    }
                                </div>
                                <div className="article-footer">
                                    <div className="article-tags">
                                        <span><i className="fas fa-bookmark"/></span>
                                        {tags.map((item2, i) =>
                                            <Link to={"/blog/category/" + item2.slug}><span dangerouslySetInnerHTML={{__html: item2.name}}/></Link>
                                        )}
                                    </div>
                                    <div className="article-share">
                                        <ul className="social">
                                            <li><a
                                                href="http://www.facebook.com/sharer/sharer.php?m2w&s=100&p[url]=@HttpContext.Current.Request.Url.AbsoluteUri&p[images][0]=@Model.CoverImg&p[title]=@ArticleTItle&p[summary]=@HttpContext.Current.Request.Url.AbsoluteUri"
                                                target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a
                                                href="http://twitter.com/intent/tweet/?text=@ArticleTItle&url=@HttpContext.Current.Request.Url.AbsoluteUri"
                                                target="_blank"><i className="fab fa-twitter"></i></a></li>
                                            <li><a
                                                href="http://www.linkedin.com/shareArticle?mini=true&url=@HttpContext.Current.Request.Url.AbsoluteUri&title=ArticleTItle&source=https%3A%2F%2Fwww.iotaci.com"
                                                target="_blank"><i className="fab fa-linkedin-in"></i></a></li>
                                            <li><a
                                                href="whatsapp://send?text=@HttpContext.Current.Request.Url.AbsoluteUri"
                                                target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                                            <li><a
                                                href="https://telegram.me/share/url?url=@HttpContext.Current.Request.Url.AbsoluteUri&text=@ArticleTItle"
                                                target="_blank"><i className="fas fa-paper-plane"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <aside className="widget-area" id="secondary">
                                <section className="widget widget_categories">
                                    <h3 className="widget-title">Categories</h3>
                                    <BlogCategory/>
                                </section>

                                <section className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Tags</h3>
                                    <BlogTags/>
                                </section>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default BlogDetail;