import React from 'react';
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
const CommingSoon = () => {
    return (
        <>
            <Helmet style={[{
                "cssText": `
            header {
                display: none;
            }
            footer {
            display: none;
            }
             `
            }]}>
                <title>IoT Academy - commingsoon</title>
            </Helmet>
            <section className="error-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="error-content">
                                <img src="/assets/img/Site/IoTAcademy-logo.png" alt="error"/>
                                <h3 className={"mb-5"}>Comming Soon</h3>
                                <Link to="/" className="btn btn-secondary">Go to Home</Link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};

export default CommingSoon;