import React, {useEffect, useState, useContext} from "react";

import AccountLayout from '../../components/Account/AccountLayout'
import {AuthorizeDataContext} from "../../context/AuthorizeDataContext";
import {apiGet, apiPost} from "../../components/Tools/apiConfig";
import {toast} from "react-toastify";
import {UserMeta} from "../../components/Tools/UserMeta";
import {Link, useHistory} from "react-router-dom";

const EditProfile = () => {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext);
    const history = useHistory();
    const [userInfo, setUserInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const getUserInfo = () => {
        let userInfo = JSON.parse(localStorage.getItem('user_meta'));
        setUserInfo(userInfo);
        setIsLoading(true)
    }


    const changeUserInfo = async (itemName, itemValue, lastOne) => {
        let localData = window.localStorage.getItem('gd-auth')
        let baseURL = `/api/user/update_user_meta/?cookie=${JSON.parse(localData).cookie}&meta_key=${itemName}&meta_value=${itemValue}`;
        try {
            const fetchData = await apiPost(baseURL).then((response) => {
                if (lastOne) {
                    if (window.localStorage.getItem('gd-auth') !== null) {
                        let localData = window.localStorage.getItem('gd-auth')
                        const baseURL = "/api/user/get_currentuserinfo/?cookie=" + JSON.parse(localData).cookie;
                        try {
                            const fetchData = apiGet(baseURL).then((response) => {
                                setIsLogin(response.data);
                                UserMeta();

                            });

                        } catch (err) {
                            console.error(err);
                        }
                    }
                    toast.success('Successfully Updated!');
                    history.push(`/myaccount`);
                }
            });
        } catch (err) {
            console.error(err);
        }
    }
    const updateProfile = (e) => {
        e.preventDefault();
        const formElements = document.getElementById("userInfo").elements;
        for (let i = 0; i < formElements.length; i++) {
            if (formElements[i].localName === "input")
                changeUserInfo(formElements[i].name, formElements[i].value)
            if (i + 1 === formElements.length) {
                changeUserInfo(formElements[i].name, formElements[i].value, true)
            }
        }
    }

    useEffect(() => {
        getUserInfo()
        if (isLogin) {
            setIsLoading(true)
        }
    }, [isLogin])

    return (
        <AccountLayout>
            <div className="card mb-4">
                <div className="card-body">
                    <form id={'userInfo'} onSubmit={updateProfile}>
                        <div className="mb-3">
                            <label>First Name</label>
                            <input type="text" name="first_name" id="First"
                                   className="form-control"
                                   defaultValue={(isLoading) ? userInfo.first_name : ''}
                            />
                        </div>
                        <div className="mb-3">

                            <label>Last Name</label>
                            <input type="text" name="last_name" id="Last"
                                   className="form-control"
                                   defaultValue={(isLoading) ? userInfo.last_name : ''}
                            />
                        </div>
                        <div className="mb-3">

                            <label>Nickname</label>
                            <input type="text" name="nickname" id="Last"
                                   className="form-control"
                                   defaultValue={(isLoading) ? userInfo.nickname : ''}
                            />
                        </div>
                        <div className="mb-3">
                            <label>Email</label>
                            <input type="text" name="email" id="email"
                                   className="form-control"
                                   defaultValue={(isLoading) ? userInfo.email : ''}/>
                        </div>
                        <div className="mb-3">
                            <label>Username</label>
                            <input type="text" name="username" id="first_name"
                                   className="form-control"
                                   defaultValue={(isLoading) ? userInfo.username : ''}
                                   readOnly={true}
                            />
                        </div>
                        <div className="text-center">
                            <button className="btn btn-dark my-4">Update Profile</button>
                        </div>
                    </form>
                </div>
            </div>
        </AccountLayout>
    );
};

export default EditProfile;