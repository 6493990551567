import {useContext} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Layout from "../components/layout/Layout";
import IndexPage from "../pages/Home/IndexPage";
import About from "../pages/Common/About";
import Contact from "../pages/Common/Contact";
import BlogList from "../pages/Blog/BlogList";
import NotFound from "../pages/Common/NotFound";
import CommingSoon from "../pages/Common/CommingSoon"
import ChangeRoute from "../components/Tools/ChangeRoute";
import BlogDetail from "../pages/Blog/BlogDetail";
import BlogListByCategory from "../pages/Blog/BlogListByCategory";
import BlogListByTag from "../pages/Blog/BlogListByTag";
import Login from "../pages/Account/Login";
import MyAccount from "../pages/Account/MyAccount";
import {AuthorizeDataContext} from "../context/AuthorizeDataContext";
import ResetPassword from "../pages/Account/ResetPassword";
import ChangePassword from "../pages/Account/ChangePassword";
import Register from "../pages/Account/Register";
import EventList from "../pages/Event/EventList";
import EventDetail from "../pages/Event/EventDetail";
import ServicesList from "../pages/Services/ServicesList";
import TicketDetail from "../pages/Ticket/TicketDetail";
import NewTicket from "../pages/Ticket/NewTicket";
import EventListByCategory from "../pages/Event/EventListByCategory";
import Ticket from "../pages/Account/Ticket";
import EditProfile from '../pages/Account/EditProfile'
import Faq from "../pages/Common/Faq";
import Policy from "../pages/Common/Policy";
import Terms from "../pages/Common/Terms";
import P2c from "../pages/Services/p2c"

//ge
import GeComingSoon from "../Germany/pages/Common/ComingSoon"

//ar
import ArComingSoon from "../Arabic/pages/Common/ComingSoon"
import OrderFallBack from "../pages/Order/OrderFallBack";
import OrderHistory from "../pages/Account/OrderHistory";

const Routing = () => {
    const {isLogin, setIsLogin} = useContext(AuthorizeDataContext)
    let lang = window.localStorage.getItem('lang')

    const Render = () => {
        switch (lang) {
            // case ('ge'): {
            //     return <GeLayout>
            //         <ChangeRoute/>
            //         <Switch>
            //             <Route path="/ge" exact>
            //                 <GeComingSoon/>
            //             </Route>
            //             <Route path="/ge/*">
            //                 <GeComingSoon/>
            //             </Route>
            //         </Switch>
            //     </GeLayout>
            // }
            case ('fa'): {
                return <Layout>
                    <ChangeRoute/>
                    <Switch>
                        <Route path="/" exact>
                            <IndexPage/>
                        </Route>

                        <Route path="/about" exact>
                            <About/>
                        </Route>
                        <Route path="/ITU-SDG-Praogram" exact>
                            <P2c/>
                        </Route>

                        <Route path="/faq" exact>
                            <Faq/>
                        </Route>

                        <Route path="/policy" exact>
                            <Policy/>
                        </Route>

                        <Route path="/terms" exact>
                            <Terms/>
                        </Route>

                        <Route path="/contact" exact>
                            <Contact/>
                        </Route>

                        <Route path="/services" exact>
                            <ServicesList/>
                        </Route>

                        <Route path="/blog" exact>
                            <BlogList/>
                        </Route>

                        <Route path="/blog/:slug" exact>
                            <BlogDetail/>
                        </Route>

                        <Route path="/event" exact>
                            <EventList/>
                        </Route>

                        <Route path="/event/category/:slug" exact>
                            <EventListByCategory/>
                        </Route>

                        <Route path="/event/:slug" exact>
                            <EventDetail/>
                        </Route>

                        <Route path="/blog/category/:slug" exact>
                            <BlogListByCategory/>
                        </Route>

                        <Route path="/blog/tag/:slug" exact>
                            <BlogListByTag/>
                        </Route>

                        <Route path="/blog" exact>
                            <BlogList/>
                        </Route>

                        <Route path="/login" exact>
                            <Login/>
                        </Route>
                        <Route path="/commingsoon" exact>
                            <CommingSoon/>
                        </Route>

                        <Route path="/register" exact>
                            <Register/>
                        </Route>

                        <Route path="/resetpassword" exact>
                            <ResetPassword/>
                        </Route>

                        <Route path="/changepassword" exact>
                            <ChangePassword/>
                        </Route>

                        <Route path="/ticket/add" exact>
                            {(isLogin.status === "ok") ? <NewTicket/> : <Login/>}
                        </Route>

                        <Route path="/ticket/:slug" exact>
                            {(isLogin.status === "ok") ? <TicketDetail/> : <Login/>}
                        </Route>

                        <Route path="/myaccount" exact>
                            {(isLogin.status === "ok") ? <MyAccount/> : <Login/>}
                        </Route>
                        <Route path="/myaccount/ticket" exact>
                            {(isLogin.status === "ok") ? <Ticket/> : <Login/>}
                        </Route>
                        <Route path="/myaccount/editprofile" exact>
                            {(isLogin.status === "ok") ? <EditProfile/> : <Login/>}
                        </Route>
                        <Route path="/myaccount/orders" exact>
                            {(isLogin.status === "ok") ? <OrderHistory/> : <Login/>}
                        </Route>
                        <Route path="*" exact>
                            <NotFound/>
                        </Route>

                    </Switch>
                </Layout>
            }
            default: {
                lang = 'en'
                window.localStorage.setItem('lang', 'en')
                return <Layout>
                    <ChangeRoute/>
                    <Switch>
                        <Route path="/" exact>
                            <IndexPage/>
                        </Route>

                        <Route path="/about" exact>
                            <About/>
                        </Route>
                        <Route path="/ITU-P2C" exact>
                            <P2c/>
                        </Route>
                        <Route path="/faq" exact>
                            <Faq/>
                        </Route>

                        <Route path="/policy" exact>
                            <Policy/>
                        </Route>

                        <Route path="/terms" exact>
                            <Terms/>
                        </Route>

                        <Route path="/contact" exact>
                            <Contact/>
                        </Route>

                        <Route path="/services" exact>
                            <ServicesList/>
                        </Route>

                        <Route path="/blog" exact>
                            <BlogList/>
                        </Route>

                        <Route path="/blog/:slug" exact>
                            <BlogDetail/>
                        </Route>

                        <Route path="/event" exact>
                            <EventList/>
                        </Route>

                        <Route path="/event/category/:slug" exact>
                            <EventListByCategory/>
                        </Route>

                        <Route path="/event/:slug" exact>
                            <EventDetail/>
                        </Route>

                        <Route path="/blog/category/:slug" exact>
                            <BlogListByCategory/>
                        </Route>

                        <Route path="/blog/tag/:slug" exact>
                            <BlogListByTag/>
                        </Route>

                        <Route path="/blog" exact>
                            <BlogList/>
                        </Route>

                        <Route path="/login" exact>
                            <Login/>
                        </Route>
                        <Route path="/commingsoon" exact>
                            <CommingSoon/>
                        </Route>

                        <Route path="/register" exact>
                            <Register/>
                        </Route>

                        <Route path="/resetpassword" exact>
                            <ResetPassword/>
                        </Route>

                        <Route path="/changepassword" exact>
                            <ChangePassword/>
                        </Route>

                        <Route path="/ticket/add" exact>
                            {(isLogin.status === "ok") ? <NewTicket/> : <Login/>}
                        </Route>

                        <Route path="/ticket/:slug" exact>
                            {(isLogin.status === "ok") ? <TicketDetail/> : <Login/>}
                        </Route>

                        <Route path="/myaccount" exact>
                            {(isLogin.status === "ok") ? <MyAccount/> : <Login/>}
                        </Route>
                        <Route path="/myaccount/ticket" exact>
                            {(isLogin.status === "ok") ? <Ticket/> : <Login/>}
                        </Route>
                        <Route path="/myaccount/orders" exact>
                            {(isLogin.status === "ok") ? <OrderHistory/> : <Login/>}
                        </Route>
                        <Route path="/order_fall_back">
                            <OrderFallBack />
                        </Route>
                        <Route path="/myaccount/editprofile" exact>
                            {(isLogin.status === "ok") ? <EditProfile/> : <Login/>}
                        </Route>

                        <Route path="/ge" exact>
                            <GeComingSoon/>
                        </Route>
                        <Route path="/ar" exact>
                            <ArComingSoon/>
                        </Route>
                        <Route path="*" exact>
                            <NotFound/>
                        </Route>

                    </Switch>
                </Layout>
            }
        }
    }

    return (
        <Router>

            <div className="App">
                <Render/>
            </div>
        </Router>

    );
};

export default Routing;
